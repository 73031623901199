@value vMenuNavMobileHeight from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {

}

.content_bottomGap {
    padding-bottom: calc(100px + vMenuNavMobileHeight);
}

@media tablet-landscape-up {
    .content_bottomGap {
        padding-bottom: 100px;
    }
}
