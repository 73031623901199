@value vTopNavHeight, vMenuNavMobileHeight, vPageVerticalGapTop from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.container {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop - vMenuNavMobileHeight);
    max-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop - vMenuNavMobileHeight);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@media tablet-landscape-up {
    .container {
        min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
        max-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
    }
}
