@value vRadius, vBoxShadow from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.popover {
    position: relative;
}

.popover_disabled {
    pointer-events: none;
}

.trigger {

}

.body {
    position: absolute;
    margin: 5px 0;
    padding: 6px;
    min-width: 100px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: vBoxShadow;
    z-index: 10;
}

.body_arrow:after {
    position: absolute;
    margin: 10px 0;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-color: inherit;
    content: '';
    transform: rotate(45deg);
    z-index: -1;
}

.body_right {
    left: calc(50% - 23px);
}

.body_right:after {
    left: 8px;
    transform: rotate(45deg) translate(10%, -50%);
}

.body_left {
    right: calc(50% - 23px)
}

.body_left:after {
    right: 8px;
    transform: rotate(45deg) translate(-50%, 10%);
}

.body_center {
    left: 50%;
    transform: translateX(-50%);
}

.body_center:after {
    left: 50%;
    transform: rotate(45deg) translate(-50%, 10%);
}

.body_top {
    bottom: 100%;
}

.body_top:after {
    bottom: -10px;
    box-shadow: rgba(0, 0, 0, 0.2) 8px 8px 20px 0;
}

.body_bottom {
    top: 100%;
}

.body_bottom:after {
    top: -12px;
    box-shadow: rgba(0, 0, 0, 0.2) -8px -8px 20px 0;
}

/*

.body_right {
    right: -40px;
}

.body_right:after {
    right: 48px;
}

.body_left {
    left: -40px;
}

.body_left:after {
    left: 48px;
}
*/

@media tablet-landscape-up {
    .body {
        min-width: 240px;
    }
}
