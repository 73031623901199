@value vBlue6 from "../../styles/variables.css";

.contentFile {
    display: flex;
}

.iconWrap {
    min-width: 40px;
}

.info {
    max-width: 100%;
    padding: 0 0 0 6px;
    display: flex;
    flex-direction: column;
}

.desc {
    font-size: .75rem;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.popoverShare {
    margin-top: auto;
}

.popoverShareBody {
    min-width: auto !important;
}

.textShare {
    font-size: .75rem;
    line-height: 1.2;
    color: vBlue6;
}

.blinking {
    animation: blink 1s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .6;
    }
    100% {
        opacity: 1;
    }
}
