@value vGutterX, vBoxShadow, vMenuNavMobileHeight, vWhite1, vGray14 from "../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.bar {
    position: fixed;
    left: 0;
    bottom: vMenuNavMobileHeight;
    width: 100%;
    height: 86px;
    padding-left: calc(vGutterX / 2);
    padding-right: calc(vGutterX / 2);
    display: flex;
    background-color: #fff;
    box-shadow: vBoxShadow;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
}

.items {
    display: flex;
}

.item {
    display: flex;
    align-items: center;
}

.item + .item {
    margin-left: 8px;
}

.goodsWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 60px;
    border: 1px solid vWhite1;
    border-radius: 8px;
}

.goods {
    width: 64px;
    height: 48px;
    /*border-radius: inherit;*/
}

.quantity {
    margin-left: 6px;
    white-space: nowrap;
}

.more {
    display: flex;
    align-items: center;
    margin-left: 14px;
    color: vGray14;
    white-space: nowrap;
}

.total {
    margin-left: auto;
    padding: 0 40px 0 20px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.totalValue {
    white-space: nowrap;
}

.actions {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.action {
    padding-left: 24px;
    padding-right: 24px;
}

@media tablet-landscape-up {
    .bar {
        bottom: 0;
    }

    .content {
        width: 1000px;
    }
}

@media desktop-up {
    .content {
        width: 1140px;
        padding-left: 160px;
    }

    .total {
        padding: 0 40px 0 20px;
    }
}

@media big-desktop-up {
    .content {
        width: 1300px;
        padding-left: 300px;
    }
}
