.item {
    padding: 10px 15px;
}

.channel {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
