@value vGray4, vGray8, vRed3 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.nav {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    list-style: none;
}

.navItem {
    position: relative;
    height: 32px;
}

.navItem + .navItem {
    margin-left: 12px;
}

.link {
    position: relative;
    display: block;
    font-size: 0;
}

.badge {
    position: absolute;
    top: -7px;
    right: -7px;
    background-color: #fff;
    color: #000;
}

.notify {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 9px;
    height: 9px;
    background-color: vRed3;
    border: 1px solid #fff;
    border-radius: 50%;
}

.avatar {
    border: 2px solid #fff;
}

.icon {
    width: 32px;
    height: 32px;
}

.icon svg * {
    fill: #fff;
}

.iconMenuItem {
    display: block;
    margin-right: 10px;
}

.iconMenuItem svg * {
    fill: vGray4;
}

@media tablet-landscape-up {
    .navItem + .navItem {
        margin-left: 24px;
    }
}
