@value vTopNavHeight, vPageVerticalGapTop, vGray17 from "../../../../styles/variables.css";

.list {
    position: relative;
}

.list_updating {
    composes: updating from "../../../../styles/modules/common.module.css";
}

.listItem {

}

.listItem + .listItem {
    margin-top: 30px;
}

.row {
    padding: 15px 0 30px;
}

.row + .row {
    border-top: 1px solid vGray17;
}

.rowTitle {
    padding: 0 0 30px;
    font-size: .875rem;
    font-weight: 500;
}
