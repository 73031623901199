@value vWhite1, vGray1 from "../../../../styles/variables.css";

.item {
    padding: 10px 0 10px 8px;
    font-size: .875rem;
    line-height: 1;
    color: vGray1;
    cursor: pointer;
}

.item:hover {
    background-color: vWhite1;
}

.highlight {
    color: #000;
}
