@value vGutter from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.catalog {

}

.breadcrumbs {
    padding-top: 24px;
    padding-bottom: vGutter;
    min-height: 52px;
    overflow-x: auto;
    overflow-y: hidden;
}

.breadcrumbs::-webkit-scrollbar {
    display: none;
}

.loader {
    position: relative;
    min-height: 40px;
    margin-top: 15px;
}

.errorText {
    padding: 40px 0;
    text-align: center;
}

@media tablet-landscape-up {
    .breadcrumbs {
        padding: 24px 0 8px 24px;
    }
}
