@value vRadius from "../../../../styles/variables.css";

.item {

}

.item + .item {
    margin-top: 6px;
}

.itemBody {
    padding-top: 20px;
    padding-left: 20px;
    border-radius: vRadius;
}

.info {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 20px;
}

.avatarWrap {
    position: relative;
    display: block;
}

.avatarParent {
    position: absolute;
    top: -12px;
    left: -12px;
}

.avatar {

}

.name {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
}

.imageWrap {
    margin: auto 0;
    max-width: 80px;
}

.region,
.rank,
.points  {
    color: #000;
}

.rank:before {
    padding-left: 4px;
    padding-right: 4px;
    content: '\2022'; /* &bull; */
}
