@value vWhite1, vGray17 from "../../styles/variables.css";
@value big-desktop-up from "../../styles/breakpoints.css";

.form {
    padding: 20px 20px;
}

.row {
    display: flex;
    align-items: center;
}

.row + .row {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid vGray17;
}

.input {
    background-color: vWhite1;
}

.input_margin {
    margin: 0 20px;
}

.action {
    padding-left: 30px;
    padding-right: 30px;
}

.imageWrap {
    position: relative;
    cursor: pointer;
}

.imageIcon {
    position: absolute;
    top: 0;
    left: 0;
}

@media big-desktop-up {
    .input {
        margin: 0 20px 0 40px;
    }
}
