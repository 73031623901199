.content {

}

.imageWrap {
    position: relative;
    min-height: 32px;
}

.image {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: inherit;
    height: auto;
}

.textBlock {
    padding: 10px 0 0;
    font-size: 1.125rem;
    text-align: center;
}

.textStrong {
    font-weight: 500;
}

.controls {
    padding: 20px 0 0;
}

.control + .control {
    margin-top: 10px;
}
