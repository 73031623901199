@value vGutter from "../../styles/variables.css";

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    outline: none;
}

.card_link {
    cursor: pointer;
    text-decoration: none;
}

.card_fullWidth {
    max-width: 100%;
    flex-basis: 100%;
}

.card_halfWidth {
    max-width: calc(50% - 4px);
    flex-basis: calc(50% - 4px);
}

.card_thirdWidth {
    max-width: calc(33.3333% - 5px);
    flex-basis: calc(33.3333% - 6px);

}

.card_fourthWidth {
    max-width: calc(25% - 6px);
    flex-basis: calc(25% - 6px);
}
