@value vGray9, vGray14 from "../../../../styles/variables.css";

.post {
    position: relative;
    padding: 15px 15px 20px;
}

.post + .post:before {
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background-color: vGray9;
    content: '';
}

.time {
    font-size: .875rem;
    line-height: 1;
    color: vGray14;
}

.title {
    padding: 6px 0 0;
    text-decoration: none;
}
