@value vGray2 from "../../styles/variables.css";

.block {
    padding-left: 12px;
    padding-right: 12px;
}

.list {

}

.list:nth-child(even) {
    background-color: vGray2;
}
