@value vGray2, vBlue10 from "../../../../styles/variables.css";

.message {
    display: flex;
    margin-top: 10px;
}

.message + .message {
    margin-top: 20px;
}

.block {
    max-width: 80%;
}

.block_left {
    padding-left: 58px;
}

.block_right {
    margin-left: auto;
    padding-right: 58px;
}

.content {

}

.content_colorDefault {
    background-color: vGray2;
}

.content_colorOwn {
    background-color: vBlue10;
}

.image {
    margin-bottom: 6px;
}

.modalContentOverlay {
    composes: overlay from "../../../../styles/modules/overlay.module.css";
    composes: overlay_dark from "../../../../styles/modules/overlay.module.css";
}

.modalContentLoader {
    min-width: 40px;;
}

.modalContentImageWrap,
.modalContentVideoWrap {
    display: flex;
    justify-content: center;
}

.modalContentImageWrap_loading,
.modalContentVideoWrap_loading {
    composes: hidden from "../../../../styles/modules/common.module.css";
}
