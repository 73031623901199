@value vPrimary1 from "../../styles/variables.css";

.container {
    position: absolute;
    left: 50%;
}

.spinner {
    display: block;
    vertical-align: text-bottom;
    border: 2px solid vPrimary1;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
}

.small {
    composes: spinner;
    width: 16px;
    height: 16px;
}

.medium {
    composes: spinner;
    width: 32px;
    height: 32px;
}

.large {
    composes: spinner;
    width: 64px;
    height: 64px;
}

.top {
    top: 0;
    transform: translate(-50%, 0);
}

.center {
    top: 50%;
    transform: translate(-50%, -50%);
}

.bottom {
    top: 100%;
    transform: translate(-50%, -100%);
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
