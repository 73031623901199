.link {
    width: 100%;
    display: block;
    flex-grow: 1;
    color: #000;
    text-decoration: none;
}

.link_disabled {
    opacity: 0.5;
    cursor: default;
}

.linkChild {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
}

.collapse {
    width: 100%;
}

.linkChildInner {
    display: flex;
    justify-content: center;
}

.iconDropDown {
    margin-left: 16px;
    width: 8px;
    height: 8px;
    composes: arrow from "../../../../styles/modules/arrow.module.css";
    composes: arrow_medium from "../../../../styles/modules/arrow.module.css";
    composes: arrow_down from "../../../../styles/modules/arrow.module.css";
    margin-top: 2px;
    border-color: #000000;
    transition: all .3s ease;
}

.iconUp {
    margin-top: 6px;
    transform: rotate(-135deg);
}

.childInner {
    width: 100%;
    padding: 5px 0 5px 10px;
}
