@value vGutter, vGutterX, vTopNavHeight, vPrimary1, vPrimary2 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.navBar {
    min-height: vTopNavHeight;
    background: linear-gradient(90deg, vPrimary1, vPrimary2);
}

.nav {
    display: flex;
    align-items: center;
    padding: vGutter;
}

@media tablet-up {
    .nav {
        padding-left: calc(vGutterX / 2);
        padding-right: calc(vGutterX / 2);
    }
}
