@value vGray10, vLoadingOpacity from "../../styles/variables.css";

.share {
    display: flex;
}

.item {
    margin: 4px;
    line-height: 0;
    border-radius: 8px;
}

.item:hover {
    opacity: vLoadingOpacity;
}

.item_default {
    background-color: vGray10;
}
