@value phone-up, tablet-landscape-up, desktop-up from "../../../../styles/breakpoints.css";

.wrap {
    position: relative;
    padding: 12px 0 20px;
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.list_loading {
    composes: updating from "../../../../styles/modules/common.module.css";
}

.item {
    box-sizing: border-box;
    max-width: calc(100% / 2);
    flex: 1 0 calc(100% / 2);
}

.month {
    max-width: 198px;
    margin: 0 auto;
    padding: 0 20px 30px;
}

@media phone-up {
    .item {
        max-width: calc(100% / 3);
        flex: 1 0 calc(100% / 3);
    }
}

@media tablet-landscape-up {
    .wrap {
        padding: 32px 32px 30px;
    }

    .item {
        max-width: calc(100% / 3);
        flex: 1 0 calc(100% / 3);
    }
}

@media desktop-up {
    .item {
        max-width: calc(100% / 4);
        flex: 1 0 calc(100% / 4);
    }

    .month {
        max-width: 220px;
    }
}
