@value tablet-landscape-up from "../../styles/breakpoints.css";

.title {

}

.titleAction {
    display: block;
    margin: 16px 0 0 auto;
}

@media tablet-landscape-up {
    .title {
        display: flex;
    }

    .titleAction {
        margin-left: auto;
    }
}
