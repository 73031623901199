@value vGray3 from "../../../../styles/variables.css";
@value desktop-up from "../../../../styles/breakpoints.css";

.row {
    display: flex;
    flex-wrap: wrap;
}

.row + .row {
    border-top: 1px solid vGray3;
}

@media desktop-up {
    .row {
        display: table-row;
    }
}
