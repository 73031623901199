@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.date {
    padding: 0 0 12px;
    font-weight: 500;
    line-height: 1;
}

.date:first-letter {
    text-transform: uppercase;
}

.greedWeekday {
    font-size: .75rem;
}

.greedDate {
    padding: 6px 0 14px;
    font-size: .75rem;
}

@media tablet-landscape-up {
    .date {
        padding: 0 4px 12px;
    }
}
