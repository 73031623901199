@value vGutter, vWhite1 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up from "../../styles/breakpoints.css";

.page {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    background-color: vWhite1;
}

.contentContainer {
    padding-bottom: 20px;
}

.navBar {
    background: transparent;
}

.blockContent {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.column {

}

.column_content {
    padding: 0 20px 20px;
}

.column_background {
    display: none;
}

.logo {
    display: flex;
    justify-content: center;
    padding: 20px 0 0;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: vGutter;
    padding-right: vGutter;
}

.background {
    height: 100%;
    padding: 30px 30px 30px 0;
}

.image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
}

@media tablet-up {

}

@media tablet-landscape-up {
    .contentContainer {
        padding-bottom: 60px;
    }

    .blockContent {
        justify-content: initial;
    }

    .logo {
        justify-content: initial;
        padding: 40px 0 0 40px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .column {
        width: 50%;
    }

    .column_content {

    }

    .column_background {
        display: block;
    }
}
