@value vGray10, vGray14 from "../../../../styles/variables.css";

.cell {
    display: flex;
}

.date {
    position: relative;
    width: 48px;
    margin-right: 8px;

}

.dateValue {
    position: relative;
    margin-top: -16px;
    line-height: 1;
    color: vGray14;
    text-align: right;
}

.content {
    position: relative;
    flex-grow: 1;
    border-top: 1px solid vGray10;
}
