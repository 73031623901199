@value vWhite2, vGray6, vGray14 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.chartTotal {
    composes: chartTotal from "../ReportsFreedom/ReportsFreedom.module.css";
}

.usersTotal {
    min-height: 1rem;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1;
}

.usersTotalCount {
    font-weight: 500;
}

.listItemBody {
    background-color: vWhite2;
}

.lineFilter {
    margin-bottom: 20px;
}

.lineFilterAction {
    display: flex;
}

.lineFilterModal {
    overflow: initial;
}

.lineFilterModalTitle {
    text-transform: lowercase;
}

.lineFilterModalTitle:first-letter {
    text-transform: uppercase;
}

.lineFilterModalBody {
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: initial;
}

.lineFilterModalRow {
    position: relative;
    max-width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
}

.lineFilterModalRow + .lineFilterModalRow {
    margin-top: 36px;
}

.lineFilterModalLabel {
    min-height: 1rem;
    color: vGray14;
    line-height: 1;
}

.lineFilterModalFieldWrap {
    border-bottom: 1px solid vGray6;
}

.lineFilterModalField {
    padding-left: 0;
    padding-right: 0;
}

.lineFilterModalFieldReset {
    composes: close from "../../styles/modules/close.module.css";
    position: absolute;
    bottom: 10px;
    right: -30px;
    width: 20px;
    height: 20px;
}

.lineFilterModalFieldReset:before,
.lineFilterModalFieldReset:after {
    background-color: vGray6;
}

.lineFilterModalFieldReset:before {
    width: 20px;
}

.lineFilterModalFieldReset:after {
    height: 20px;
}

.lineFilterModalFooter {
    margin-top: 12px;
}

@media tablet-landscape-up {
    .block {
        padding-left: 20px;
        padding-right: 20px;
    }

    .lineFilterModalRow {
        max-width: 350px;
    }
}
