@value vWhite1, vGray7 from "../../../styles/variables.css";

.item {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 8px;
    font-size: .875rem;
    line-height: 1;
    color: #000;
    cursor: pointer;
}

.item:hover {
    background-color: vWhite1;
}

.item_active {
    pointer-events: none;
    color: vGray7;
}
