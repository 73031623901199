.wrap {
    height: 100%;
}

.link {
    display: block;
    height: 100%;
}

.video {
    height: 100%;
}
