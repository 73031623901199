@value vGutter from "../../../styles/variables.css";
@value tablet-up from "../../../styles/breakpoints.css";

.footer {
    padding: 10px vGutter 20px;
}

@media tablet-up {
    .footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}
