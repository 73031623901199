@value vGutter from "../../styles/variables.css";
@value phone-only from "../../styles/breakpoints.css";

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: vGutter;
    row-gap: vGutter;
}

.container + .container {
    margin-top: vGutter;
}

@media phone-only {
    .container {
        padding-left: vGutter;
        padding-right: vGutter;
    }
}
