@value vGutterX from "../../styles/variables.css";

.background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom 40px;
}

.background_widthGutter {
    width: calc(100% - vGutterX);
    margin-left: auto;
    margin-right: auto;
}
