@value vBlue6 from "../../../../../../styles/variables.css";

.attach {

}

.attach_row {
    display: flex;
}

.attach_row + .attach_row {
    margin-top: 10px;
}

.attach_column + .attach_column {
    margin-left: 10px;
}

.button {
    padding: 2px 8px;
    display: flex;
    flex-basis: 100px;
    align-items: center;
    border: 1px solid vBlue6;
    border-radius: 15px;
    line-height: 1;
}

.link {
    composes: button;
    color: #000;
    text-decoration: none;
}

.icon {
    margin-right: 4px;
    position: relative;
    composes: clickable from "../../../../../../styles/modules/common.module.css";
}

.text {
    font-size: .8125rem;
    line-height: 1;
}

.desc {
    padding: 0 10px;
}
