@value vGray1, vViolet4 from "../../../../styles/variables.css";

.string {
    padding-bottom: 0;
    color: #000;
}

.string_clickable {
    cursor: pointer;
}

.wrap {
    padding: 0;
    border-radius: 0;
    background-color: inherit;
}

.row {
    display: flex;
    align-items: center;
    min-height: 88px;
    padding: 16px 8px;
}

.iconWrap {
    position: relative;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    flex-shrink: 0;
    background-color: vViolet4;
}

.icon {
    position: absolute;
    max-width: 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text {
    padding: 0 10px 0 20px;
    font-size: 1.125rem;
    line-height: 24px;
}

.values {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 8px;
    text-align: right;
}

.valueWrap {
    position: relative;
    font-size: 0;
}

.valueSpinner {
    left: auto;
    right: 0;
    transform: translateY(-50%);
}

.value,
.unit {
    font-size: 1.25rem;
    line-height: .8;
}

.value_grey {
    color: vGray1;
}

.unit {
    margin-left: 8px;
    color: vGray1;
}

.sub {
    font-size: .75rem;
}

.arrow {
    margin-left: 8px;
    border-color: vGray1;
    border-width: 1px;
}
