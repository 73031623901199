@value vGutter from "../../../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.card {

}

.action {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.iconLikeOff svg path {
    stroke: #000;
}

@media tablet-landscape-up {

}

@media desktop-up {

}

@media big-desktop-up {

}
