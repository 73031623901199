@value vGray5 from "../../../../styles/variables.css";

.action {
    width: auto;
}

.action_button + .action_button {
    margin-left: 5px;
}

.action_buttonActive {
    background-color: vGray5;
    color: #fff;
    cursor: default;
}

.action_posRight {
    margin-left: auto;
}
