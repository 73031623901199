@value vHoverTransition, vGray14 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.header {
    padding: 0 0 30px;
    display: flex;
    align-items: center;
}

.header_mobile {
    padding-bottom: 20px;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
    line-height: 1;
    text-decoration: none;
}

.title_mobile {
    font-size: 1rem;
}

.count {
    margin-left: 8px;
}

.showAll {
    padding-top: 16px;
    text-align: center;
}

.showAllLink {
    color: vGray14;
    transition: color vHoverTransition;
}

.showAllLink:hover {
    color: #000;
}

.showAllLink_mobile {
    margin-left: auto;
    font-size: 1rem;
}

.actions {
    padding-top: 30px;
}

@media tablet-landscape-up {
    .header {
        justify-content: space-between;
    }
}
