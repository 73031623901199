@value vGray14 from "../../../../styles/variables.css";

.item {
    display: flex;
}

.item + .item {
    margin-top: 8px;
}

.body {
    display: block;
    text-decoration: none;
}

.date {
    color: vGray14;
    line-height: 1;

}

.title {

}

.actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.control + .control {
    margin-left: 20px;
}

.iconEdit {

}

.iconTrash svg path {
    fill: #000;
}
