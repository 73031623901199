@value vPrimary1 from "../../../../styles/variables.css";
@value desktop-up from "../../../../styles/breakpoints.css";

.header {
    padding: 8px 12px 10px;
}

.item {

}

.item_m_center {
    display: flex;
    justify-content: center;
}

.item + .item {
    margin-top: 12px;
}

.periodsAction {
    padding-left: 30px;
    padding-right: 30px;
}

.periodsAction + .periodsAction {
    margin-left: 5px;
}

.periodsAction_active {
    background-color: vPrimary1;
    border-color: vPrimary1;
    color: #fff;
    cursor: default;
}

.action {
    display: flex;
    align-items: center;
    font-size: .75rem;
    font-weight: 400;
}

.actionPlus {
    margin-left: 16px;
    font-size: 30px;
    line-height: 20px;
}

@media desktop-up {
    .header {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px 12px;
    }

    .item + .item {
        margin-top: 0;
    }

    .item_fullWidth {
        flex-grow: 1;
    }

    .item_pLeft {
        padding-left: 20px;
    }

    .item_order1 {
        order: 1;
    }

    .item_order2 {
        order: 2;
    }

    .item_order3 {
        order: 3;
    }

    .item_m_center {
        display: initial;
    }
}
