.arrow {
    display: block;
    width: 12px;
    height: 12px;
    border: solid #000;
    border-width: 0 2px 2px 0;
}

.arrow_top {
    transform: rotate(-135deg);
}

.arrow_bottom {
    transform: rotate(45deg);;
}

.arrow_left {
    transform: rotate(135deg);
}

.arrow_right {
    transform: rotate(-45deg);
}
