@value vWhite2 from "../../../../styles/variables.css";

.card {
    width: 100%;
    height: 100%;
    padding: 16px 10px 20px;
    display: flex;
    flex-direction: column;
    background-color: vWhite2;
    border-radius: 10px;
}

.imageWrap {
    min-height: 50px;
}

.image {
    margin: 0 auto;
}

.title {
    margin-top: 8px;
    flex-grow: 1;
    font-size: .75rem;
    line-height: 1;
    color: #000;
    text-align: center;
    word-break: break-word;
    hyphens: auto;
}

.text {
    margin-top: 8px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    color: #000;
    text-align: center;
}
