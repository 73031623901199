@value vHoverTransition, vPrimary3, vGray1, vGreen1 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
}

.link:hover .text,
.text_active {
    color: vPrimary3 !important;
}

.link_disabled {
    opacity: .5 !important;
    cursor: default;
}

.placeholder {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.placeholderHover:hover .inner .text,
.placeholderHover:hover .inner .textChildPlaceholder .textChild {
    color: vPrimary3 !important;
}

.inner {
    display: flex;
    align-items: center;
}

.inner:hover .text,
.inner:hover .textChild {
    color: vPrimary3;
}

.inner:hover .icon svg * {
    fill: vPrimary3;
}

.icon {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
}

.icon_active svg * {
    fill: vPrimary3 !important;
}

.text {
    display: none;
    padding-left: 16px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
    word-break: break-word;
    hyphens: auto;
    transition: color vHoverTransition;
}

.text_active {
    color: vPrimary3 !important;
}

.text_disabled,
.icon_disabled {
    opacity: .5;
    cursor: default;
}

.textChildPlaceholder {
    padding-left: 46px;
}

.textChild {
    display: block;
    font-size: 1.25rem;
    font-weight: 400;
    color: #000;
    transition: color vHoverTransition;
}

.count {
    display: none;
    padding-right: 6px;
    font-size: 1.25rem;
    font-weight: 500;
    color: vGray1;
}

.badge {
    display: none;
    margin-left: auto;
}

.iconDropDown {
    display: none;
    margin-left: 24px;
    composes: arrow from "../../../../styles/modules/arrow.module.css";
    composes: arrow_medium from "../../../../styles/modules/arrow.module.css";
    composes: arrow_down from "../../../../styles/modules/arrow.module.css";
    margin-top: -4px;
    border-color: #000000;
    transition: all vHoverTransition;
}

.iconUp {
    margin-top: 6px;
    transform: rotate(-135deg);
}

@media tablet-landscape-up {
    .text,
    .count,
    .iconDropDown,
    .badge {
        display: initial;
    }
}
