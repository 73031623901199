@value vGray10, vGray14 from "../../../../styles/variables.css";

.header {
    padding: 12px 18px 24px 12px;
    border-bottom: 1px solid vGray10;
}

.headerTop {
    padding: 4px 6px 0;
}

.headerBottom {
    display: flex;
    padding: 12px 0 0;
}
