.content {
    padding: 0 0 0 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info {
    flex-grow: 1;
}

.data {

}
