.wallet {
    composes: payout from "../WalletItem/WalletItem.module.css";
    padding: 20px 20px 24px;
    background: #fff;
}

.title {
    margin: 4px 0 30px;
    font-size: 1.5rem;
    line-height: 1;
}

.amount {
    composes: amount from "../WalletItem/WalletItem.module.css";
    color: #000;
}

.desc {
    composes: desc from "../WalletItem/WalletItem.module.css";
    color: #000;
}
