@value vRadius from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {

}

.header {
    padding: 20px 8px 0;
    background-color: #fff;
    border-radius: vRadius vRadius 0 0;
}

.post {

}

.postHeader {
    padding-top: 24px;
}

.comments {
    margin-top: 2px;
    background-color: #fff;
    border-radius: 0 0 vRadius vRadius;
}

.commentsItems {
    padding: 15px 0 0;
}

@media tablet-landscape-up {
    .header {
        padding: 20px 24px 0;
    }
}
