@value vGutterX, vRed1 from "../../styles/variables.css";

.block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(vGutterX / 2);
    padding: 10px;
}

.icon {
    margin-right: 16px;
    width: 30px;
    height: 30px;
}

.text {
    text-transform: uppercase;
    color: vRed1;
}
