@value vGray5 from "../../../../styles/variables.css";

.filter {
    display: flex;
}

.lineAction {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    border: 1px solid vGray5;
    border-radius: 12px;
    color: #000;
    white-space: nowrap;
}

.lineAction_active {
    background-color: vGray5;
    color: #fff;
    cursor: default;
}

.lineAction + .lineAction {
    margin-left: 6px;
}
