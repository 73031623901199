@value vPrimary3, vRed1, vGray6, vViolet1, vHoverTransition from "../../../../styles/variables.css";

.item {
    display: flex;
}

.item + .item {
    margin-top: 40px;
}

.image {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 180px;
    height: 134px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border: 1px solid vGray6;
    overflow: hidden;
    opacity: 1;
    outline: none;
    text-decoration: none;
    transition: opacity .3s ease;
}

.image_loading {
    opacity: 0;
}

.body {
    padding: 0 0 0 20px;
    width: 100%;
}

.title {
    display: flex;
    margin-top: 4px;
    min-height: 40px;
    font-size: 1.25rem;
    line-height: 1;
    color: #000;
    outline: none;
    text-decoration: none;
    transition: color vHoverTransition;
}

.title:hover {
    color: vPrimary3;
}

.prices {
    /*margin-top: 20px;*/
}

.price {
    margin-right: 30px;
    font-size: 1.125rem;
    line-height: 1;
}

.price_cv {
    color: vViolet1;
}

.price_kicks {
    color: vPrimary3;
}

.controls {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
}

.control {
    position: relative;
    composes: clickable from "../../../../styles/modules/common.module.css";
}

.iconTrash {
    width: 26px;
    height: 30px;
}

.iconPresent {
    width: 20px;
    height: 20px;
}

.iconPresent {
    margin: -1px 6px 0 0;
}
