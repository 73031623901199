@value tablet-landscape-up, desktop-up from "../../styles/breakpoints.css";

.block {
    padding: 20px 10px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media tablet-landscape-up {
    .block {
        padding-left: 30px;
        padding-right: 30px;
    }
}
