.wrap {
    display: flex;
}

.avatar {

}

.body {
    flex-basis: 100%;
    margin-top: 7px;
    margin-left: 10px;
}
