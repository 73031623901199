@value vGray14 from "../../../../styles/variables.css";
@value tablet-landscape-up, desktop-up from "../../../../styles/breakpoints.css";

.nothing {
    font-size: .875rem;
    color: vGray14;
}

@media desktop-up {
    .nothing {
        padding: 100px 0;
    }
}
