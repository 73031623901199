@value vGutter, vGray3, vGray4 from "../../styles/variables.css";
@value phone-only, tablet-up from "../../styles/breakpoints.css";

.breadcrumbs {
    overflow-x: auto;
    overflow-y: hidden;
}

.breadcrumbs::-webkit-scrollbar {
    display: none;
}

.title {
    padding-bottom: 16px;
    font-size: 1.25rem;
    line-height: 1;
}

.subTitle {
    padding-bottom: 16px;
    font-size: 1.125rem;
    line-height: 1;
    color: vGray4;
}

.blockDesc {
    padding: 20px;
}

.profile {
    position: relative;
}

.friends {
    margin: 16px 0;
    padding: 4px vGutter;
    border-width: 1px 0 1px 0;
    border-color: vGray3;
    border-style: solid;
}

.loader {
    position: relative;
    min-height: 40px;
}

@media phone-only {
    .title,
    .subTitle,
    .blockDesc {
        margin-left: vGutter;
        margin-right: vGutter;
    }
}

@media tablet-up {
    .friends {
        padding-left: 0;
        padding-right: 0;
    }
}
