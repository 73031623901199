@value vGutter, vGutterX from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.container {
    padding: 0 vGutter calc(vGutterX / 2);
    overflow: auto hidden;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
}

@media tablet-landscape-up {
    .container {
        padding: 20px 0 20px 20px;
    }
}
