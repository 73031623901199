@value vGray14, vGray18 from "../../styles/variables.css";

.tag {
    display: inline-flex;
    align-items: center;
    padding: 3px 2px;
    background-color: vGray18;
    border-radius: 15px;
    color: #fff;
    line-height: 1;
}

.content {
    padding: 0 10px;
    white-space: nowrap;
}

.action {
    padding-right: 5px;
}

.cross {
    width: 12px;
    height: 12px;
    composes: close from "../../styles/modules/close.module.css";
}

.cross:before {
    width: 12px;
    height: 2px;
}

.cross:after {
    width: 2px;
    height: 12px;
}
