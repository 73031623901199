@value vGutter, vGray1 from "../../../../styles/variables.css";

.account {
    composes: fullWidth from "../ReportItem/ReportItem.module.css";
}

.account + .account {
    margin-top: vGutter;
}

.account:first-child .body {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.account:not(:last-child) .body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.body {
   flex-direction: row;
}

.image {
    max-width: 60px;
    height: auto;
    border-radius: 30px;
}

.content {
    padding-left: 20px;
}

.parent {
    color: #000;
}

.name {
    font-size: 1.25rem;
    color: #000;
}

.region {
    color: vGray1;
}

.status {
    padding-right: 8px;
    color: #000;
}

.value {
    color: #000;
}
