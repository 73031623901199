@value vGray8, vGray14 from "../../../../styles/variables.css";

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    line-height: 1;
    color: #000;
    text-align: center;
}

.subtitle {
    margin-top: 10px;
    font-size: .8125rem;
    line-height: 1;
    color: vGray14;
    text-align: center;
}

.iconArrow {
    display: flex;
    transform: rotate(-90deg);
}

.iconArrow svg path {
    fill: vGray8;
}

.message {
    margin-top: 10px;
    text-align: center;
}

.columns {
    margin: 15px 0 10px;
    display: flex;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33.3333%;
}

.rank {
    padding: 0 10px;
}

.rankChart {
    margin-left: auto;
    margin-right: auto;
}

.rankImageWrap {
    max-width: 100px;
    margin: 0 auto;
}

.rankValue,
.rankText,
.rankName {
    color: #000;
    line-height: 1;
    text-align: center;
}

.rankValue {
    margin-top: 10px;
    font-weight: 700;
}

.rankText {
    margin-top: 5px;
    font-size: .8125rem;
}

.rankName {
    font-size: .8125rem;
}
