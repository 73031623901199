@value vGutter, vRadiusMiddle from "../../../../styles/variables.css";

.header {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin: 0 0 vGutter;
    padding: 0 15px 0 22px;
    height: 75px;
    border-radius: vRadiusMiddle;
    text-decoration: none;
}

.header:active {
    color: inherit;
}

.name {
    flex-grow: 1;
    font-size: 1.25rem;
    line-height: 1;
    color: #fff;
    mix-blend-mode: difference;
}

.actions {

}
