.avatarLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.avatarLink_right {
    margin-left: auto;
}

.avatarUser {
    margin-left: 12px;
    color: #000;
    font-weight: 600;
}
