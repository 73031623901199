@value vGray14 from "../../../../styles/variables.css";

.group {
    width: 100%;
}

.date {
    margin: 20px 0 15px;
    color: vGray14;
    text-align: center;
}
