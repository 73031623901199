.chat {
    padding: 0 30px;
    height: 100%;
}

.chat::-webkit-scrollbar {
    width: 2px;
}

.chat::-webkit-scrollbar-track {
    background: transparent;
}

.chat::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, .5);
    border-radius: 2px;
    border: transparent;
}

.forwards {

}

.avatarWrap {
    margin-top: 20px;
    display: flex;
}

.messageWrap {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0;
}
