@value vLinkColor, vBlue6, vGray10, vGray14, vGray16 from "../../styles/variables.css";

.field {
    font-size: .875rem;
    border-color: transparent;
}

.field_select {
    composes: default from "../../components/Input/Input.module.css";
}

.field_input {
    max-width: 130px;
    padding-left: 36px;
    padding-right: 10px;
    font-size: 1rem;
    letter-spacing: 10px;
}

.field_inputFilled {
    color: #000;
}

.fieldError_center {
    right: 0;
    text-align: center;
}

.selectArrow svg * {
    fill: vGray16
}

.label {
    font-size: .875rem;
    line-height: 1;
    color: vGray14;
}

.confirm {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.privacy {
    margin-top: 42px;
    font-size: 1rem;
    line-height: 1.2;
}

.privacyRow {

}

.privacyRow + .privacyRow {
    margin-top: 10px;
}

.privacyText {
    color: vGray14;
}

.privacyLink {
    color: vLinkColor;
}

.controls {
    margin-top: 30px;
}

.control {
    width: 100%;
}

.control_primary {
    background-color: vBlue6;
    border-color: vBlue6;
    color: #fff;
}
