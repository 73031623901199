@value vGray14 from "../../../../styles/variables.css";

.user {
    display: block;
}

.friends {
    display: inline-flex;
    align-items: center;
    min-height: 30px;
    text-decoration: none;
}

.friendsText {
    font-size: .875rem;
    color: vGray14;
}

.friendsText_mLeft {
    margin-left: 8px;
}
