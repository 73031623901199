@value vGutter, vGutterX from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.block {
    padding: 12px vGutter;
}

.block + .block {
    margin-top: calc(vGutterX / 2);
}

@media tablet-landscape-up {
    .block {
        padding: 20px 24px;
    }
}
