@value vGutter, vGutterX, vPrimary3, vYellow3, vRed1 from "../../../../styles/variables.css";
@value phone-only, tablet-landscape-up from "../../../../styles/breakpoints.css";

.block {
    display: flex;
    overflow: hidden;
    margin-bottom: calc(vGutterX / 2);
}

.column {
    width: 33.33%;
    padding: 10px;
}

.column_vAlignCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row {
    z-index: 1;
}

.row + .row {
    margin-top: 6px;
}

.row_double + .row_double {
    margin-top: 12px;
}

.info {

}

.base {
    position: relative;
    text-align: center;
}

.user {
    background-color: vRed1;
    color: #fff;
}

.bgImage {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80%;
    z-index: 0;
}

.text {
    font-size: 1rem;
    line-height: 1;
}

.text_small {
    font-size: .875rem;
}

.text_large {
    font-size: 1.25rem;
}

.badge {
    padding: 2px 4px;
    border-radius: 6px;
}

.badge_yellow {
    background-color: vYellow3;
}

.badge_blue {
    background-color: vPrimary3;
    color: #fff;
}

@media phone-only {
    .block {
        margin-left: vGutter;
        margin-right: vGutter;
    }
}

@media tablet-landscape-up {
    .info {
        padding-left: 24px;
    }
}
