@value tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.filters {
    padding: 0 0 10px;
}

.filter {

}

.filter_loading {
    composes: updating from "../../styles/modules/common.module.css";
}

@media tablet-up {

}

@media tablet-landscape-up {

}

@media desktop-up {

}

@media big-desktop-up {

}
