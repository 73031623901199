.wrap {
    position: relative;
    height: 100%;
}

.loader {
    height: 100%;
}

.iframe {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: none;
    transition: opacity .3s ease;
}

.iframe_loading {
    opacity: 0;
    height: 0;
}
