@value vPrimary1, vBlack1 from "../../../../styles/variables.css";

.reply {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 30px;
}

.content {
    position: relative;
    margin-left: 24px;
    padding: 5px 20px 5px 15px;
    overflow: hidden;
}

.content:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    content: '';
    background-color: vPrimary1;
}

.name {
    margin-bottom: 8px;
    font-size: .875rem;
    color: vPrimary1;
}

.body {

}

.message {
    display: block;
    font-size: .875rem;
    color: vBlack1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.messageImg,
.messageVideo {
    width: 60px;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    z-index: 1;
    composes: close from "../../../../styles/modules/close.module.css";
}

.close:before {
    width: 18px;
    height: 2px;
}

.close:after {
    width: 2px;
    height: 18px;
}

.iconReply svg path {
    fill: vPrimary1;
}
