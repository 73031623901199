@value vWhite1 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.event {
    position: absolute;
    padding: 4px 10px;
    align-items: flex-start;
    background-color: vWhite1;
    border-radius: 8px;
    border: 1px solid #fff;
    text-decoration: none;

}

.text {
    display: -webkit-box;
    line-height: 1.4;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text_oneLine {
    line-height: 1.1;
    -webkit-line-clamp: 1;
}

@media tablet-landscape-up {
    .text_oneLine {
        line-height: 1.2;
    }
}
