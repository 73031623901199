@value vWhite1, vGray14 from "../../../../styles/variables.css";

.event {
    display: flex;
    max-width: 100%;
    padding: 5px 6px;
    background-color: vWhite1;
    border-radius: 8px;
    text-decoration: none;
}

.event + .event {
    margin-top: 2px;
}

.time {
    padding-right: 4px;
    font-size: .75rem;
    line-height: 14px;
    color: vGray14;
    white-space: nowrap;
}

.title {
    font-size: .75rem;
    line-height: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
