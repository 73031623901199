@value vGray3 from "../../../../styles/variables.css";

.title {
    margin-bottom: 24px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.row {

}

.row + .row {
    margin-top: 20px;
    padding-top: 18px;
    border-top: 1px solid vGray3;
}

.text {
    font-size: 1.125rem;
    line-height: 1.2;
}
