.preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    filter: blur(15px);
    opacity: 1;
    transition: visibility .6s ease, opacity .6s ease;
    will-change: filter;
    z-index: 1;
}

.preview_hide {
    visibility: hidden;
    opacity: 0;
}
