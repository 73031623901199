@value vRed0 from "../../styles/variables.css";

.wrap {
    position: relative;
}

.badge {
    position: absolute;
    top: -7px;
    right: -7px;
    background-color: vRed0;
}

.notify {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: vRed0;
    border: 1px solid #fff;
    border-radius: 50%;
}
