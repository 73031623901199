@value vLinkColor, vBlue3, vBlue4, vGray1, vGray4, vDanger from "../../styles/variables.css";

.formField {
    position: relative;
}

.formField + .formField {
    margin-top: 32px;
}

.formFieldColumn {
    display: flex;
    flex-direction: column;
}

.formFieldDesc {
    margin-top: 12px;
    color: vGray1;
}

.formFieldIcon {
    right: 14px;
    background-color: #fff;
}

.formFieldIcon {
    width: 30px;
    height: 30px;
}

.formFieldContent {
    position: static;
    padding: 7px 0 0;
}

.formFieldContent + .formFieldContent {
    margin-left: 14px;
}

.formFieldContent_maxWidth {
    max-width: calc(100% - 52px); /* 66 - country select width */
}

.formInput {
    font-size: 1.125rem;
    color: #000;
}

.formSelectField {
    padding: 0;
    height: auto;
    font-size: 1.125rem;
    color: #000;
}

.formRadio + .formRadio {
    margin-top: 20px;
}

.formRadioText {
    font-size: 1.25rem;
}

.formButtonSubmit {
    font-size: 1.25rem;
}

.fieldPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
}

.labelPhoto {
    position: relative;
    cursor: pointer;
}

.labelImage {
    margin-left: auto;
    margin-right: auto;
}

.labelText {
    margin-top: 24px;
    font-size: 1.125rem;
    color: vBlue4;
    text-align: center;
}

/*.avatarField {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 0 54px;
}

.avatarWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.avatarWrap:hover .avatarText {
    color: vBlue3;
    transition: color .3s ease;
}

.avatarText {
    margin-top: 24px;
    font-size: 1.125rem;
    color: vBlue4;
}*/

.descLink {
    color: vLinkColor;
}

.datePickerWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.datePickerWrap_active {
    position: static;
    padding: 7px 0 0;
}

.datePicker {
    height: 100%;
    font-size: 1.125rem;
    line-height: 1;
}

.datePicker_active {
    height: auto;
}

.iconAddPhoto {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}
