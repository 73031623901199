@value vGutter, vGray5, vGray10 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up from "../../styles/breakpoints.css";

.content {
    padding-left: vGutter;
    padding-right: vGutter;
}

.title {
    padding: 0 0 10px;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;
}

.header {

}

.header_bordered {
    border-bottom: 1px solid vGray10;
}

.block {

}

.modalAddEventBody,
.modalEditEventBody {
    padding-left: 20px;
    padding-right: 20px;
}

.modalAddEventHeader,
.modalEditEventHeader {
    padding-left: 20px;
    border-bottom: 1px solid vGray5;
}

@media tablet-up {
    .content {
        margin-left: 0;
        margin-right: 0;
    }

    .modalAddEventBody,
    .modalEditEventBody {
        padding-left: 34px;
        padding-right: 34px;
    }

    .modalAddEventHeader,
    .modalEditEventHeader {
        padding-left: 34px;
    }
}

@media tablet-landscape-up {
    .title {
        display: none;
    }

    .block_m_noBg {
        background-color: #fff;
    }
}
