@value vGutter, vGutterX, vWhite2, vGray6, vGray14 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up, big-desktop-up from "../../styles/breakpoints.css";

.block {
    position: relative;
    padding: 20px vGutter;
}

.block + .block {
    margin-top: calc(vGutterX / 2);
}

.blockHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.blockTitle {
    min-height: 1.25rem;
    font-size: 1.25rem;
    line-height: 1;
}

.blockSubTitle {
    color: vGray14;
    line-height: 1;
}

.blockBody {
    margin-top: 12px;
}

.blockActions {
    margin-left: auto;
}

.chartTotal {
    margin-top: 20px;
    margin-bottom: 30px;
    min-height: 1.75rem;
    font-size: 1.75rem;
    line-height: 1;
    text-transform: lowercase;
}

.chartTotal_updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.rankList {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(vGutter / -2);
    margin-right: calc(vGutter / -2);
}

.rankListItem {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    margin-bottom: vGutter;
    padding-left: calc(vGutter / 2);
    padding-right: calc(vGutter / 2);
}

.rankListLink {
    text-decoration: none;
}

.listNoData {
    margin-top: 0;
}

@media tablet-up {
    .block {
        padding-left: 20px;
        padding-right: 20px;
    }

    .blockBody {
        margin-top: 20px;
    }

    .rankListItem {
        max-width: 25%;
        flex-basis: 25%;
    }
}

@media tablet-landscape-up {

}

@media big-desktop-up {
    .rankListItem {
        max-width: 16.6667%;
        flex-basis: 16.6667%;
    }
}
