@value vPrimary1, vGray1, vGray14 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.row {
    flex-wrap: wrap;
}

.rowText {
    font-size: 1.25rem;
}

.rowField {

}

.subRow {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.subRowText {
    color: vGray14;
}

.textarea {
    resize: vertical;
}

.fieldCommunity {
    color: vPrimary1;
    font-size: 1.25rem;
}

.fieldCommunity_disabled {
    color: vGray1 !important;
}

.fieldDateTime {

}

.actions {
    margin-top: 30px;
}

.controls {
    display: flex;
}

.controls_mt {
    margin-top: 15px;
}

.controls_alignRight {
    margin-left: auto;
}

.controls_contentRight {
    justify-content: right;
}

.control {
    line-height: 0;
}

.control + .control {
    margin-left: 24px;
}

.iconPhotoBank svg path {
    stroke: vPrimary1;
}

.iconImage svg path,
.iconImage svg circle,
.iconImage svg rect {
    stroke: vPrimary1;
}

.iconVideo svg rect,
.iconVideo svg circle,
.iconVideo svg polygon {
    stroke: vPrimary1;
}

.iconGoods svg line,
.iconGoods svg path,
.iconGoods svg circle {
    stroke: vPrimary1;
}

@media tablet-up {
    .subRow {
        flex-direction: row;
        align-items: center;
    }

    .fieldDateTime {
        margin-left: auto;
    }
}
