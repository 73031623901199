@value vPrimary1, vPrimary3, vBlue0, vBlue6, vViolet0, vGray5, vLoadingOpacity from "../../styles/variables.css";

._reset {
    padding: 0;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.custom {
    composes: _reset;
}

.default:disabled,
.bordered:disabled,
.bordered2:disabled,
.bordered3:disabled,
.transparent:disabled {
    opacity: vLoadingOpacity;
    cursor: default;
}

.default {
    composes: _reset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1;
    color: #fff;
    text-decoration: none;
}

.default {
    background: linear-gradient(90deg, vViolet0, vBlue0);
    border-radius: 8px;
}

.default_bordered {
    composes: default;
    padding: 2px;
}

.default_bordered .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    color: vPrimary3;
}

.bordered {
    composes: _reset;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border: 1px solid vPrimary1;
    border-radius: 4px;
    color: vPrimary1;
    text-decoration: none;
}

.bordered2 {
    composes: _reset;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
    border: 1px solid vGray5;
    border-radius: 12px;
    color: #000;
    line-height: 1;
    white-space: nowrap;
}

.bordered3 {
    composes: _reset;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 23px;
    font-size: .875rem;
    color: #000;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
}

.transparent {
    composes: _reset;
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    line-height: 1;
    color: #000;
    text-decoration: none;
}

.text {
    composes: _reset;
    background: none;
}

.textUpper {
    text-transform: uppercase;
}

.size30 {
    height: 30px;
}

.size32 {
    height: 32px;
}

.size36 {
    height: 36px;
}

.size40 {
    height: 40px;
}

.size44 {
    height: 44px;
}

.size46 {
    height: 46px;
}

.size48 {
    height: 48px;
}
