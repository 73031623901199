.collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;
}

.datepicker {
    margin-top: 4px;
    padding: 10px 8px;
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
