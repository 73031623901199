@value vHoverTransition, vWhite1, vGray3 from "../../styles/variables.css";

.counter {
    position: relative;
    max-width: 120px;
    border-radius: 6px;
    overflow: hidden;
}

.control {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    font-size: 1.25rem;
    font-weight: 300;
    background: none;
    outline: none;
    transition: background-color vHoverTransition;
}

.control:disabled {
    cursor: default;
}

.control:not(:disabled):hover {
    background-color: vWhite1;
}

.controlLeft {
    left: 0;
}

.controlRight {
    right: 0;
}

.input {
    composes: reset from "../../components/Input/Input.module.css";
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
    border: 1px solid vGray3;
    border-radius: 6px;
    font-size: 1.125rem;
    text-align: center;
}
