@value vRadiusMiddle, vPrimary1, vGray14, vGray20, vGreen6, vGreen7 from "../../styles/variables.css";
@value desktop-up from "../../styles/breakpoints.css";

.card {

}

.image {
    composes: image from "../Card/CardImage/CardImage.module.css";
}

.body {
    padding-left: 12px;
    padding-right: 12px;
}

.body_bRadiusNone {
    border-radius: 0;
}

.title {
    min-height: 48px;
    line-height: 1.2;
    text-align: left;
}

.status {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 44px;
    height: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius:vRadiusMiddle;
    background: linear-gradient(vGray14, vGray20);
    color: #fff;
    font-size: .625rem;
    font-weight: 500;
    line-height: 1;
    z-index: 1;
}

.status_active {
    background: linear-gradient(vGreen6, vGreen7);
}

.footer {
    padding-left: 12px;
    padding-right: 12px;
}

@media desktop-up {
    .body {
        padding-left: 16px;
        padding-right: 16px;
    }

    .footer {
        padding-left: 16px;
        padding-right: 16px;
    }
}
