@value vGutterX, vViolet5 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up, desktop-up from "../../styles/breakpoints.css";

.block {
    position: relative;
    margin: 20px auto 0;
    padding: 42px 60px;
    max-width: 460px;
}

.block_noBg {
    background-color: transparent;
}

.title {
    margin-bottom: 30px;
    color: vViolet5;
    font-size: 1.5rem;
    text-align: center;
}

.text {
    font-size: 1.25rem;
    text-align: center;
}

.image {
    max-width: 100%;
    height: auto;
}

@media tablet-landscape-up {
    .block {
        margin-top: 50px;
    }
}
