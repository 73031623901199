@value vGray2 from "../../../../styles/variables.css";

.imageTitle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.slider {
    background-color: vGray2;
}

.slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
