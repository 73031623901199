.chevron {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
}

.down {
    transform: rotate(45deg);
}

.up {
    transform: rotate(-135deg);
}

.right {
    transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
}
