@value vGray3, vGray5 from "../../styles/variables.css";

.periods {
    display: flex;
    align-items: center;
}

.date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.datePreviewGroup {
    display: flex;
}

.datePreviewGroupItem {
    border: 1px solid vGray3;
    border-radius: 12px;
}

.datePreviewGroupAction {
    padding: 7px 8px 6px;
    height: 100%;
}

.datePreviewGroupDelimiter {
    padding: 0 10px;
    height: 34px;
    line-height: 34px;
}

.dateButtonArrow {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
}

.dateArrowLeft,
.dateArrowRight {

}

.dateArrowLeft {
    transform: rotate(180deg);
}

.dateArrowRight {

}

.datePreview {
    flex-grow: 1;
    padding: 4px 8px;
    border: 1px solid vGray3;
    border-radius: 12px;
    text-align: center;
}
