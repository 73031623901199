@value vPrimary1, vGreen3, vGray14 from "../../../../styles/variables.css";

.title {
    display: block;
    font-weight: 600;
    line-height: 1.25;
    color: #000;
    text-decoration: none;
    word-break: break-word;
}

.message {
    display: flex;
    margin-top: 4px;
    padding-right: 34px;
    text-decoration: none;
}

.messageSender {
    margin-right: 4px;
    font-size: .875rem;
    line-height: 1.3;
    color: #000;
    white-space: nowrap;
}

.messageText {
    display: -webkit-box;
    font-size: .875rem;
    line-height: 1.3;
    color: vGray14;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.messageText_lines-1 {
    -webkit-line-clamp: 1;
}

.messageText_lines-2 {
    -webkit-line-clamp: 2;
}

.messageText_read {
    color: #000;
}

.date {
    color: vGray14;
    white-space: nowrap;
    text-align: right;
}

.date:first-letter {
    text-transform: uppercase;
}

.count {
    position: absolute;
    right: 12px;
    top: 8px;
}

.countInformer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    background-color: vPrimary1;
    border-radius: 50%;
    color: #fff;
    font-size: .875rem;
    line-height: 1;
}

.error {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
}

.status {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding-right: 4px;
}
