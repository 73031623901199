@value vGutter from "../../../styles/variables.css";

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    outline: none;
    flex-shrink: 0;
    flex-grow: 1;
}

.item_link {
    cursor: pointer;
    text-decoration: none;
}
