@value vGray1 from "../../../../styles/variables.css";

.group + .group,
.transaction + .transaction {
    margin-top: 32px;
}

.group {

}

.transaction {

}

.date {
    margin-bottom: 32px;
    font-size: .875rem;
    color: vGray1;
}
