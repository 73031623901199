@value vGutter, vGutterX, vWhite1, vGray14 from "../../styles/variables.css";
@value tablet-up, desktop-up from "../../styles/breakpoints.css";

.content {
    position: relative;
    display: flex;
    flex-direction: column;
}

.contentInner {
    position: relative;
    margin-top: calc(vGutterX / 2);
    flex-grow: 1;
}

.nav,
.walletCurrent {
    margin-left: vGutter;
    margin-right: vGutter;
}

.block {
    position: relative;
    padding: 24px 12px;
}

.blockHeader {

}

.blockTitle {
    font-size: 1.125rem;
    line-height: 1;
}

.blockActions {
    margin-left: auto;
    line-height: 1;
}

.amountSum {
    composes: amountSum from "components/WalletPayoutCurrent/WalletPayoutCurrent.module.css";
    color: #000;
}

.amountDesc {
    composes: amountDesc from "components/WalletPayoutCurrent/WalletPayoutCurrent.module.css";
    color: #000;
}

.filter {
    margin-bottom: calc(vGutterX / 2);
    padding: 0 0 10px;
    border-bottom: 2px solid vWhite1;
}

.filter_updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.list {
    padding: 0;
}

.listItem {
    max-width: calc(50% - 8px);
    margin-right: 16px;
}

.listItem_updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.transactions {
    position: relative;
}

.loaderTransactions {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 60px;
}

.reports {
    position: relative;
    margin-top: calc(vGutterX / 2);
}

.mobileBar {
    padding-left: vGutter;
    padding-right: vGutter;
}

.payoutsMobile,
.reportMobile {
    position: relative;
    padding-top: calc(vGutterX / 2);
}

.payoutsMobile {
    display: flex;
    overflow-x: auto;
}

.iconCalendar svg path,
.iconCalendar svg circle {
    fill: vGray14;
}

.iconCalendar svg path:last-of-type {
    fill: none;
    stroke: vGray14;
}

@media tablet-up {
    .nav,
    .walletCurrent {
        margin-left: 0;
        margin-right: 0;
    }

    .block {
        padding: 24px 20px;
    }

    .mobileBar {
        padding-left: 0;
        padding-right: 0;
    }
}

@media desktop-up {
    .listItem {
        max-width: 100%;
        margin-right: 0;
    }

    .listItem + .listItem {
        margin-top: 16px;
    }
}
