@value vHoverTransition, vWhite1, vGray6, vGray14 from "../../../../styles/variables.css";

.trigger {
    display: flex;
    align-items: center;
    padding: 6px 18px;
    background-color: vWhite1;
    border-radius: 15px;
    transition: vHoverTransition;
}

.trigger:hover {
    background-color: vGray6;
}

.text {
    padding-right: 8px;
    font-size: 1rem;
    line-height: 1;
    color: #000;
}

.iconDropDown svg path {
    fill: vGray14;
}
