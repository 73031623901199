@value vGray13 from "../../../../styles/variables.css";

.wrap {
    display: flex;
    align-items: center;
    padding: 40px;
}

.imageWrap {
    max-width: 100px;
}

.body {
    padding-left: 30px;
    flex-grow: 1;
}

.tile {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.text {
    margin-top: 8px;
    line-height: 1;
    color: vGray13;
}
