@value vPrimary1 from "../../../../styles/variables.css";

.arrow {
    width: 4px;
    height: 10px;
    border-color: vPrimary1;
    border-width: 0 1px 1px 0;
}

.arrow + .arrow {
    margin-left: 2px;
}
