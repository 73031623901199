.datePicker {
    position: relative;
}

.datePickerAction {

}

.datePickerInput {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    width: 0;
    height: 0;
    border: 0;
    outline: none;
}
