@value vBlue6, vGray14, vGray16 from "../../styles/variables.css";

.avatarPreview {
    margin: 0 auto;
    width: 128px;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
}

.field {
    font-size: .875rem;
    border-color: transparent;
}

.field_group {
    padding: 0;
    composes: default from "../../components/Input/Input.module.css";
}

.field_select {
    padding-left: 0;
    padding-right: 0;
    font-size: .875rem;
}

.citySelect {
    max-width: calc(100% - 66px); /* 66 - country select width */
}

.fieldCountryCode {
    padding-left: 14px;
    padding-right: 4px;
}

.fieldPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectArrow svg * {
    fill: vGray16
}

.label {
    font-size: .875rem;
    line-height: 1;
    color: vGray14;
}

.labelPhoto {
    position: relative;
    cursor: pointer;
}

.radio {
    padding: 2px 15px 2px 5px;
    margin: 10px 2px 0 0;
    border: 1px solid vGray16;
    border-radius: 7px;
    cursor: pointer;
}

.radio_active {
    border-color: #000;
    cursor: default;
}

.controls {
    composes: controls from "../AuthPhoneForm/AuthPhoneForm.module.css";
}

.control {
    width: 100%;
}

.control + .control {
    margin-top: 12px;
}

.control_primary {
    background-color: vBlue6;
    border-color: vBlue6;
    color: #fff;
}

.iconAddPhoto {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
