@value vGray14 from "../../../../styles/variables.css";

.content {
    justify-content: center;
}

.name {
    line-height: 1.2;
    font-weight: 500;
    color: #000;
}

.info {
    justify-content: center;
}

.date {
    color: vGray14;
    text-transform: capitalize;
}
