@value vRed4 from "../../../../styles/variables.css";

.error {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
}

.errorIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background-color: vRed4;
    border-radius: 50%;
    font-size: .625rem;
    line-height: 14;
    color: #fff;
}
