@value vGray5 from "../../../../styles/variables.css";

.field {
    height: 24px;
}

.fieldText {
    display: block;
    font-size: 1rem;
}

.fieldText:first-letter {
    text-transform: uppercase;
}

.option {

}

.option_active {
    cursor: default;
    pointer-events: none;
    color: vGray5;
}

.optionText {
    margin-left: auto;
    margin-right: auto;
}

.optionText:first-letter {
    text-transform: uppercase;
}
