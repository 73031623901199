@value vRed4 from "../../../../styles/variables.css";

.line {
    position: absolute;
    left: 56px;
    right: 1px;
    height: 1px;
    background-color: vRed4;
}

.line:before {
    position: absolute;
    top: 0;
    left: -5px;
    width: 5px;
    height: 5px;
    background-color: vRed4;
    border-radius: 50%;
    content: '';
    transform: translateY(-50%);
}
