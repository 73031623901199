@value phone-up, tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../../../../../styles/breakpoints.css";

.checkData {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    padding: 20px 12px;
    background-color: rgba(0, 0, 0, .45);
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

.checkData_relative {
    position: relative;
    margin-left: auto;
}

.block {

}

.block:after {
    display: block;
    padding: 10px 0 0;
    height: 32px;
    font-size: 1.5rem;
    color: #fff;
    content: '* * * * * * * * * * * * * * * * * * * * * * * * *';
    text-align: center;
    overflow: hidden;
}

.title {
    max-height: 96px;
    font-size: 1.25rem;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    overflow: hidden;
}

.products {
    padding: 12px 0 0;
    font-size: 1.25rem;
    color: #fff;
}

.total {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px 0 0;
}

.totalText {
    padding-right: 10px;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #fff;
}

.totalSum {
    font-size: 1.25rem;
    color: #fff;
    white-space: nowrap;
}

.info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0 0;
}

.infoQR {
    width: 54px;
}

.infoItem {
    font-size: 1.125rem;
    line-height: 20px;
    color: #fff;
    text-align: right;
    white-space: nowrap;
}

@media tablet-landscape-up {
    .block:after {
        height: 36px;
        font-size: 1.75rem;
    }

    .title {
        max-height: 168px;
        font-size: 1.75rem;
        line-height: 1.2;
    }

    .products {
        padding: 24px 0 0;
    }

    .total {
        padding: 24px 0 0;
    }

    .totalText {
        font-size: 1.5rem;
    }

    .totalSum {
        font-size: 1.5rem;
    }

    .info {
        padding: 40px 0 0;
    }
}
