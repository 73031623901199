@value vLoadingOpacity, vRadiusSmall, vPrimary1, vGray14 from "../../styles/variables.css";

.label {
    display: flex;
    align-items: center;
    width: fit-content;
}

.checkbox {
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -1;
    opacity: 0;
}

.checkbox:not(:checked) + .box:after {
    display: none;
}

.checkbox:disabled + .box {
    cursor: default;
}

.checkbox:disabled + .box:before,
.checkbox:disabled + .box:after {
    opacity: vLoadingOpacity;
}

.checkbox:disabled + .text {
    color: vGray14;
}

.checkbox:disabled + .text:after {
    background-color: vGray14;
}

.box {
    position: relative;
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-size: 1.125rem;
    line-height: 24px;
    cursor: pointer;
}

.box_default:before,
.box_small:before {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    content: '';
}

.box_default:before {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid vGray14;
    border-radius: vRadiusSmall;
}

.box_small:before {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: calc(vRadiusSmall / 2);
}

.box_default:after,
.box_small:after {
    position: absolute;
    content: '';
    transform: rotate(-45deg);
}

.box_default:after {
    top: 8px;
    left: 8px;
    width: 16px;
    height: 10px;
    border-radius: 1px;
    border-left: 2px solid vPrimary1;
    border-bottom: 2px solid vPrimary1;
}

.box_small:after {
    top: 4px;
    left: 3px;
    width: 15px;
    height: 8px;
    border-radius: 1px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
}

.text {
    padding-left: 10px;
}
