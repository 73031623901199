@value vGray17 from "../../../../styles/variables.css";

.mediaBar {
    display: flex;
    padding: 5px 15px;
}

.info {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.name {
    color: vGray17;
    font-size: .875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.date {
    color: vGray17;
    font-size: .6875rem;
}

.actions {
    display: flex;
    margin-left: auto;
}

.action {

}

.action + .action {
    margin-left: 15px;
}

.iconShare svg line,
.iconShare svg path {
    stroke: vGray17;
}

.iconDownload svg path:first-child {
    stroke: vGray17;
}

.iconDownload svg path:last-child {
    fill: vGray17;
}

.iconClose {
    composes: close from "../../../../styles/modules/close.module.css";
    width: 20px;
    height: 20px;
}

.iconClose:before {
    width: 20px;
    height: 2px;
    background-color: vGray17;
}

.iconClose:after {
    width: 2px;
    height: 20px;
    background-color: vGray17;
}

.iconClose:hover:after,
.iconClose:hover:before {
    background-color: vGray17;
}
