.wrap {
    display: flex;
    height: 100%;
}

.info,
.data {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
}

.infoImage {
    margin: 0 auto;
    background-repeat: no-repeat;
    content: '';
}

.infoImage_pv {
    width: 84px;
    height: 90px;
    background-position: 0 0;
}

.infoImage_cc {
    width: 90px;
    height: 90px;
    background-position: -84px 0;
}

.infoTitle {
    margin-top: 10px;
    font-size: 1.25rem;
    line-height: 1;
    text-align: center;
}

.infoSubtitle {
    margin-top: 10px;
    font-size: .875rem;
    line-height: 1.1;
    text-align: center;
}

.dataTitle_success,
.dataSubtitle_success {
    color: #4AA257; /* TODO */
}

.dataTitle_danger,
.dataSubtitle_danger {
    color: #ff5071; /* TODO */
}

.data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #eef0f4; /* TODO */
    border-radius: 10px;
}

.dataText {
    line-height: 1;
    color: #7d85a4; /* TODO */
    text-align: center;
}

.dataValue {
    margin-top: 10px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
}
