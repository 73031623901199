.image {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    outline: none;
    overflow: hidden;
}

.image_link {
    cursor: pointer;
    text-decoration: none;
}
