.item {
    overflow: hidden;
}

.wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.link {
    display: block;
}

.image {
    margin-left: auto;
    margin-right: auto;
}

.imagePlaceholder {
    max-width: 100%;
}
