@value vGray1, vRed3, vBlue6, vPrimary1, vViolet4 from "../../styles/variables.css";

.tag {
    color: #fff;
    composes: tag from "../../styles/modules/tag.module.css";
}

.tag_status-0 {
    background-color: vPrimary1;
}

.tag_status-1 {
    background-color: vViolet4;
}

.tag_status-2 {
    background-color: vBlue6;
}

.tag_status-3 {
    background-color: vRed3;
}

.tag_status-4 {
    background-color: vGray1;
}
