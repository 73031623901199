@value vGutterX from "../../styles/variables.css";
@value desktop-up from "../../styles/breakpoints.css";

.registration {
    width: 348px;
}

.form {
    padding: 20px 0 0;
}

@media desktop-up {
    .form {
        padding-top: 0;
    }
}
