@value vRadius, vModalVerticalGapTop, vModalVerticalGapBottom, vModalVerticalGapMobile, vBoxShadow from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up, big-desktop-up from "../../styles/breakpoints.css";

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: vModalVerticalGapMobile;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    max-height: calc(100% - vModalVerticalGapMobile - vModalVerticalGapMobile);
    background: #fff;
    border-radius: vRadius;
    box-shadow: vBoxShadow;
    outline: none;
    /*overflow: hidden;*/
    opacity: 1;
    transition: opacity .3s ease;
    z-index: 100;
}

.modal_fullWidth {

}

.modal_mobileFullScreen {

}

.modal_hidden {
    opacity: 0;
}

.small,
.small2,
.smallCenter,
.small2Center,
.mediumCenter {
    left: 50%;
    right: initial;
    transform: translateX(-50%);
}

.small,
.smallCenter {
    max-width: 360px;
}

.small2,
.small2Center {
    max-width: 560px;
}

.smallCenter,
.small2Center {
    top: 40%;
    transform: translate(-50%, -40%);
}

.fullWidth {
    top: 50%;
    left: 50%;
    right: initial;
    background: transparent;
    box-shadow: none;
    transform: translate(-50%, -50%);
}

@media phone-only {
    .modal_mobileFullScreen {
        width: 100%;
        min-height: 100%;
        margin: 0 auto;
        top: 0;
        left: 0;
    }
}

@media tablet-up {
    .modal {
        max-height: calc(100% - vModalVerticalGapTop - vModalVerticalGapBottom);
    }

    .small,
    .mediumCenter {
        top: 40%;
        transform: translate(-50%, -40%);
    }

    .small2,
    .medium,
    .large {
        top: vModalVerticalGapTop;
        transform: translateX(-50%);
    }

    .medium,
    .large {
        left: 50%;
        right: initial;
    }

    .medium,
    .mediumCenter,
    .large {
        max-width: 752px;
    }

    .fullWidth {
        max-width: 80%;
        max-height: 80%;
    }
}

@media tablet-landscape-up {
    .medium,
    .mediumCenter {
        max-width: 620px;
    }

    .large {
        max-width: 800px;
    }
}

@media big-desktop-up {
    .medium,
    .mediumCenter {
        max-width: 670px;
    }
}
