.folder {

}

.folder_clickable {
    cursor: pointer;
}

.content {
    color: #000;
    text-align: center;
}
