@value vGutterX, vGutter, vMenuNavMobileHeight, vGray1, vBlue7 from "../../styles/variables.css";
@value phone-only, tablet-up from "../../styles/breakpoints.css";

.content {
    position: relative;
}

.infoBlock {
    position: relative;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.sideBar {
    position: relative;
}

.sideBarBlock {
    padding: 14px 0 0;
}

.ranks {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ranksChevron {
    margin-top: -30px;
    border-color: vGray1;
}

.ranksChevron_active {
    border-color: vBlue7;
}

@media phone-only {
    .content {
        min-height: auto;
        padding-bottom: calc(vGutterX / 2);
    }

    .sideBar {
        padding-bottom: calc(vMenuNavMobileHeight + vGutter);
    }
}

@media tablet-up {
    .ranks {
        align-items: flex-start;
    }

    .ranksChevron {
        margin-top: 30px;
    }
}
