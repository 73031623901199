@value vGutterX, vPageVerticalGapTop, vGray13 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.pageContainer {
    padding: calc(vGutterX / 2) 0 0;
}

.sideBar {
    width: 256px;
    background-color: #fff;
}

.sideBarLogo {
    position: relative;
    display: block;
    padding: 5px 0 0 16px;
    height: 60px;
    text-decoration: none;
}

.userInfoBlock {
    padding: 20px 15px 30px;
}

.menuNav {
    padding: 34px 0 0;
}

@media tablet-landscape-up {
    .pageContainer {
        padding-top: vPageVerticalGapTop;
    }

    .menuNav {
        padding-bottom: 40px;
    }
}
