@value vGutterX, vRadiusSmall, vGray2, vGray22 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {

}

.updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.list {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.listCol {
    padding: 12px 6px;
    background-color: vGray2;
    text-align: left;
}

.block {
    margin-top: calc(vGutterX / 2);
    padding: 12px;
}

.block_gray {
    background-color: vGray22;
}

.blockHeader {
    flex-wrap: wrap;
    min-height: 48px;
    align-items: center;
}

.blockHeaderInner {
    padding: 15px;
    align-items: center;
    background-color: vGray22;
    border-radius: vRadiusSmall;
}

.blockTitle {
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 500;
}

.blockActions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.blockAction {
    margin-left: 8px;
}

.blockActionSettings {
    margin-left: 30px;
}

.blockBody {
    padding: 10px 0 0;
}

.blockBody_list {
    overflow: auto hidden;
}

.listSettings {

}

.listSettingsItem {
    white-space: nowrap;
}

@media tablet-landscape-up {
    .listCol:first-child {
        padding-left: 14px;
    }

    .listCol:last-child {
        padding-right: 14px;
    }
}
