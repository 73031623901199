.link {
    width: 100%;
    display: block;
    flex-grow: 1;
    color: #000;
    text-decoration: none;
}

.link_disabled {
    opacity: 0.5;
    cursor: default;
}

.qr {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
