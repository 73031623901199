@value vGray4 from "../../styles/variables.css";

.wrap {
    position: relative;
}

.content {
    word-break: break-word;
    hyphens: auto;
}

.content:empty::before {
    content: attr(placeholder);
    color: vGray4;
}

.content:empty:focus::before {
    /*content: "";*/
}

.sticker {
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
    height: 24px;
}
