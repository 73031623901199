.wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image {
    margin-left: auto;
    margin-right: auto;
}

.video {
    width: 100%;
}
