@value vGray0 from "../../../../styles/variables.css";

.group {

}

.group + .group {
    margin-top: 20px;
}

.dateWrap {
    position: sticky;
    top: 5px;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.date {
    padding: 4px 12px;
    background-color: vGray0;
    border-radius: 16px;
    color: #fff;
    opacity: .9;
}
