@value vPrimary2, vGray2, vGray3, vGray14, vGreen2, vBlue1, vBlue8, vRed3, vRed4, vYellow4 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.row:hover .col {
    background-color: vGray2;
}

.col {
    padding: 8px 6px;
    font-size: .875rem;
    line-height: 1.2;
    text-align: left;
    transition: background-color .3s;
}

.col_bordered {
    border-bottom: 1px solid vGray3;
}

.text {
    font-size: .875rem;
}

.text_action {
    white-space: nowrap;
}

.text_danger {
    color: vRed3;
}

.link {
    color: vBlue1;
    transition: color .3s ease;
}

.link:hover {
    color: vPrimary2;
}

.product {
    font-size: .8125rem;
}

.status {
    line-height: 1.4;
}

.status_default {
    color: vGray14;
}

.status_20 {
    color: vRed4;
}

.status_30 {
    color: vYellow4;
}

.status_40 {
    color: vBlue8;
}

.status_50 {
    color: vGreen2;
}

.status_60 {
    color: vGray14;
}

.price {
    text-align: right;
    white-space: nowrap;
}

@media tablet-landscape-up {
    .col:first-child {
        padding-left: 12px;
    }

    .col:last-child {
        padding-right: 12px;
    }
}
