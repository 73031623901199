@value vWhite2 from "../../styles/variables.css";

.list {
    margin-top: 20px;
}

.item + .item {
    margin-top: 6px;
}

.body {
    background-color: vWhite2;
}
