@value vGray4, vGray5 from "../../styles/variables.css";

.wrap {
    flex-shrink: 0;
    line-height: 0;
    transition: background-color .3s ease;
    border-radius: 50%;
    overflow: hidden;
}

.wrap_unround {
    /*border-radius: 50%;*/
    /*overflow: hidden;*/
}

.image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
}

.wrap_unround,
.wrap_unround .image {
    border-radius: 0;
}
