@value vGutter, vModalVerticalGapTop, vModalVerticalGapBottom, vModalVerticalGapMobile, vWhite1 from  "../../../styles/variables.css";
@value tablet-up from "../../../styles/breakpoints.css";

@value vHeightModalHeader: 66px; /* modal header height */
@value vHeightModalFooter: 70px; /* modal footer height */
@value vHeightBrowserMobileNavBar: 50px; /* - mobile browser nav panel height */

@value vHeightIndent: vHeightModalHeader + vHeightModalFooter;
@value vHeightIndentMobile:  vHeightIndent + vHeightBrowserMobileNavBar;

.body {
    max-height: calc(100vh - (vHeightIndentMobile + vModalVerticalGapMobile + vModalVerticalGapMobile));
    min-height: 80px;
    padding: 10px vGutter 20px;
    overflow-y: auto;
}

.body_bgDefault {
    background-color: vWhite1;
}

.body_fullWidth {
    max-height: 100%;
    padding: 0;
    overflow: hidden;
}

.body_fullScreen {
    max-height: calc(100vh - vHeightModalHeader);
}

@media tablet-up {
    .body {
        max-height: calc(100vh - (vHeightIndent + vModalVerticalGapTop + vModalVerticalGapBottom));
        padding-left: 20px;
        padding-right: 20px;
    }

    .body_fullWidth {
        padding: 0;
    }
}
