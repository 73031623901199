@value vBlue10 from "../../../../styles/variables.css";

.user {
    display: block;
}

.highlight {
    display: inline-block;
    background-color: vBlue10; /* vBlue10 PV only */
}
