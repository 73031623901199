@value vGray2 from "../../../../styles/variables.css";

.content {
    position: relative;
}

.content + .content {
    margin-top: 24px;
}

.slider {
    background-color: vGray2;
}

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
