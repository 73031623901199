.title {
    font-size: 1.25rem;
    line-height: 1;
}

.info {
    padding: 10px 0 0;
    display: flex;
    justify-content: space-between;
}

.infoButtonMembers {
    width: auto;
}

.infoButtonMembers_disabled {
    cursor: default;
    pointer-events: none;
}

.chart {
    padding: 10px 0 0;
}
