.wrap {

}

.img {
    max-width: 100%;
    height: auto;
}

.image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
