@value vGutter from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.nav {
    margin-top: calc(vGutter * 2);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.tabs {
    padding: 10px 24px 0;
}

.tabsItem {
    font-size: 1.25rem;
    padding-left: 10px;
}

.tabsItem + .tabsItem {
    margin-left: 10px;
}

.feed {
    position: relative;
    flex-grow: 1;
}

.friends {
    margin-top: 20px;
}

@media tablet-landscape-up {
    .tabsItem + .tabsItem {
        margin-left: 100px;
    }
}
