@value tablet-up from "../../../../styles/breakpoints.css";

.rating {
    display: flex;
}

.col {
    display: flex;
    flex-basis: 33.3333%;
    align-items: center;
    justify-content: center;
}

.col:first-child {
    justify-content: flex-start;
}

.col:last-child {
    justify-content: flex-end;
}

.desc {
    padding: 0 0 0 4px;
    font-size: 1rem;
    line-height: 1;
}

@media tablet-up {
    .desc {
        padding-left: 14px;
        font-size: 1.25rem;
    }
}
