@value vRadius, vBoxShadow, vPrimary1, vGray3, vBlue6 from "../../styles/variables.css";
@value desktop-up from "../../styles/breakpoints.css";

.stickers {
    position: relative;
    font-size: 0;
}

.trigger {
    position: relative;
    font-size: 0;
    composes: clickable from "../../styles/modules/common.module.css";
}

.popup {
    position: absolute;
    bottom: 100%;
    max-width: 280px;
    min-width: 280px;
    margin-bottom: 16px;
    background-color: #fff;
    box-shadow: vBoxShadow;
    border-radius: vRadius;
    z-index: 10;
}

.popup:after {
    position: absolute;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    content: '';
    transform: rotate(45deg);
    z-index: -1;
}

.popup_right {
    right: -20px;
}

.popup_right:after {
    right: 20px;
}

.popup_left {
    left: -20px;
}

.popup_left:after {
    left: 20px;
}

.popupBody {
    max-height: 300px;
    overflow-y: auto;
}

.popupBody::-webkit-scrollbar {
    width: 2px;
}

.popupBody::-webkit-scrollbar-track {
    background: transparent;
}

.popupBody::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, .5);
    border-radius: 2px;
    border: transparent;
}

.popupFooter {
    background-color: vPrimary1;
    border-radius: 0 0 vRadius vRadius;
}

.iconSticker svg circle {
    fill: #000;
}

.iconSticker svg path {
    stroke: #000;
}

@media desktop-up {
    .popup {
        max-width: 410px;
        min-width: 352px;
    }
}
