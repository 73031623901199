@value vGray26 from "../../../styles/variables.css";

.field {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    padding: 0 14px;
    font-size: 1.125rem;
    line-height: 1;
    color: #000;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.disabled {
    color: vGray26;
    pointer-events: none;
}

.fieldRow {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.fieldArrow {
    display: flex;
    flex-shrink: 0;
    margin-left: 10px;
    z-index: 1;
}

.fieldArrow_open {
    transform: rotate(180deg);
}

.fieldArrow svg * {
    fill: #000;
}
