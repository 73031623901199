@value vTopNavHeight, vPageVerticalGapTop, vGray12, vGray14 from "../../../styles/variables.css";
@value tablet-landscape-up from "../../../styles/breakpoints.css";

.form {

}

.blockSideBar {
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
}

.blockSideBar_updating {
    composes: updating from "../../../styles/modules/common.module.css";
}

.searchSideBar {
    padding: 20px 15px;
}

.searchListTitle,
.searchListItem {
    padding-left: 15px;
    padding-right: 15px;
}

.actionsSideBar {
    padding: 20px;
}

.actionAddMember {

}

.modalBody {

}

@media tablet-landscape-up {
    .modalBody {
        min-height: 504px;
    }
}
