@value vGray14 from "../../../../styles/variables.css";
@value big-desktop-up from "../../../../styles/breakpoints.css";

.info {
    display: flex;
    min-height: 30px;
    padding: 0 0 18px;
}

.infoItem {
    display: flex;
    align-items: center;
}

.infoItemSeparate {
    position: relative;
    width: 24px;
}

.infoItemSeparate:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 50%;
    content: '';
    transform: translate(-50%);
}

.infoText {
    margin-left: 6px;
    font-size: 1.25rem;
}

.content {
    display: flex;
    align-items: center;
    min-height: 50px;
}

.text {
    display: flex;
    flex-basis: 100%;
    min-height: 100%;
    align-items: center;
    font-size: .875rem;
    color: vGray14;
}

.text_center {
    justify-content: center;
}

.text_mLeft {
    margin-left: 2px;
}

.actions {
    padding: 18px 12px 24px;
}

.action + .action {
    margin-top: 15px;
}

.actionChat {
    width: auto;
    margin-left: auto;
    flex-shrink: 0;
}

.iconChat {

}

.iconChat svg path {
    stroke: #000;
    stroke-width: 1.2;
}

@media big-desktop-up {
    .text {
        font-size: 1rem;
    }

    .text_mLeft {
        margin-left: 10px;
    }
}
