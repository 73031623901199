@value big-desktop-up from "../../../../styles/breakpoints.css";

.container {
    padding: 13px 0 2px;
    background-color: #fff;
    overflow-x: auto;
    scrollbar-width: none;
}

.desc {
    display: none;
}

.filters {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
}

@media big-desktop-up {
    .container {
        display: flex;
        padding: 0;
        background-color: transparent;
    }

    .desc {
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    .filters {
        padding: 0;
        width: 100%;
    }
}
