.block {
    position: relative;
    min-height: 162px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.body {

}

.amountSum {
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 500;
    color: #fff;
}

.amountDesc {
    font-size: .875rem;
    color: #fff;
    text-transform: uppercase;
}
