@value vPrimary3 from "../../../../styles/variables.css";

.showAll {
    margin-top: 12px;
    color: vPrimary3;
    transition: color .3s ease;
}

.showAll:hover {
    color: #000;
}

.modalBody {
    padding-left: 0;
    padding-right: 0;
}
