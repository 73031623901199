@value vBlue7 from "../../../../styles/variables.css";

.rating {
    display: flex;
    justify-content: space-between;
}

.rating + .rating {
    margin-top: 50px;
}

.name,
.total {
    font-size: 1.25rem;
    line-height: 1;
}

.name {
    padding-right: 10px;
}

.total {
    color: vBlue7;
}
