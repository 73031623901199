@value tablet-landscape-up from "../../styles/breakpoints.css";

.container {
    display: flex;
    align-items: center;
}

.avatars {
    display: flex;
    align-items: center;
}

.image {
    border: 4px solid #fff;
    border-radius: 50%;
}

.text {
    padding-left: 5px;
    font-size: 1rem;
    line-height: 1.2;
    color: #000;
}

@media tablet-landscape-up {
    .text {
        line-height: 1.5;
    }
}
