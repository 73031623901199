@value vWhite1, vGray5 from "../../styles/variables.css";

.form {
    display: flex;
    align-items: center;
}

.form_disabled {
    composes: disabled from "../../styles/modules/common.module.css";
}

.fieldInput {
    padding: 11px 15px;
    height: auto;
    flex-grow: 1;
    background-color: vWhite1;
    border-color: vGray5;
}

.textContent {
    outline: none;
}

.actionStickers {
    margin-right: 20px;
}

.actionAttach {
    position: relative;
    margin-left: 20px;
    cursor: pointer;
}

.actionSend {
    width: auto;
    margin-left: 20px;
}
