.wrap {
    position: relative;
    overflow: hidden;
}

.link {
    display: block;
}

.video {
    width: 100%;
}

.poster {
    position: absolute;
    width: 100%;
    height: 100%;
}

.poster_hide {
    visibility: hidden;
}

.playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
}
