@value vGray1, vColorPaymentStatusDefault, vColorPaymentStatus0, vColorPaymentStatus1, vColorPaymentStatus2, vColorPaymentStatus3, vColorPaymentStatus4 from "../../../../styles/variables.css";

.transaction {
    display: flex;
}

.body {
    flex-basis: 100%;
    padding-left: 18px;
}

.link {
    display: block;
}

.avatar {
    height: 54px;
}

.title {
    font-size: 1.25rem;
}

.order {
    display: flex;
    justify-content: space-between;
}

.info {
    padding: 0 8px 0 0;
}

.desc + .status {
    margin-left: 4px;
}

.desc {
    color: vGray1;
}

.status {
    color: vColorPaymentStatusDefault;
}

.status_color0 {
    color: vColorPaymentStatus0;
}
.status_color1 {
    color: vColorPaymentStatus1;
}
.status_color2 {
    color: vColorPaymentStatus2;
}
.status_color3 {
    color: vColorPaymentStatus3;
}
.status_color4 {
    color: vColorPaymentStatus4;
}

.amount {
    font-size: 1.5rem;
    line-height: 1;
    white-space: nowrap;
}
