@value vGutter, vPrimary1, vPrimary2, vGutterX from "../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.colLeft {

}

.colCenter {
    display: flex;
    flex: 0 1 auto;
    padding-left: 10px;
}

.colRight {
    flex: 0 0 auto;
    margin-left: auto;
}

.searchWrap {
    position: relative;
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    margin: 0 24px;
}

.searchInput {

}

.iconSearch {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

@media tablet-landscape-up {
    .colLeft {
        width: 30%;
    }

    .colRight {

    }
}

@media desktop-up {
    .colLeft,
    .colRight {
        width: 25%;
    }

    .colCenter {
        width: 50%;
    }
}

@media big-desktop-up {

}
