@value vDefaultHeight: 110px;

.textarea {
    composes: reset from "Input.module.css";
    padding-top: 12px;
    padding-bottom: 12px;
    height: vDefaultHeight;
}

.default {
    composes: default from "Input.module.css";
}

.dynamic {
    composes: dynamic from "Input.module.css";
    padding-top: 26px;
}

.placeholder {
    composes: placeholder from "Input.module.css";
    top: 12px;
    transform: translateY(0);
}

.transparent {
    composes: transparent from "Input.module.css";
}

.gray {
    composes: gray from "Input.module.css";
}
