@value vBoxShadow, vRadiusMiddle, vRed1, vRed4 from "../../../../styles/variables.css";

.menu {
    position: absolute;
    border-radius: vRadiusMiddle;
    box-shadow: vBoxShadow;
    z-index: 10;
}

.item {

}

.item_danger {
    color: vRed4;
}

.item_danger:hover {
    color: vRed1;
}
