@value vGray1, vBlue7 from "../../../../styles/variables.css";
@value tablet-up from "../../../../styles/breakpoints.css";

.rank {

}

.rank_active {

}

.iconWrap {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 40px;
    height: 40px;
    border: 2px solid vGray1;
    border-radius: 50%;
}

.iconWrap:not(.iconWrap_active) {
    filter: grayscale(1);
}

.iconWrap_active {
    border-color: vBlue7;
    width: 50px;
    height: 50px;
}

.icon {
    margin: auto;
}

.name,
.rate {
    font-size: .875rem;
    color: vGray1;
    text-align: center;
}

.rate {
    font-weight: 700;
    line-height: 1;
}

.name_active,
.rate_active {
    color: vBlue7;
}

@media tablet-up {
    .iconWrap,
    .iconWrap_active {
        width: 70px;
        height: 70px;
    }

    .name {
        font-size: 1rem;
    }

    .rate {
        font-size: 1.25rem;
    }
}
