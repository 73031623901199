@value vTopNavHeight, vMenuNavMobileHeight, vPageVerticalGapTop, vGray10, vGray12, vGray14 from "../../../styles/variables.css";
@value tablet-landscape-up, desktop-up from "../../../styles/breakpoints.css";

.blockContent {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop - vMenuNavMobileHeight);
    max-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop - vMenuNavMobileHeight);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.blockSideBar {
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
    overflow: hidden;
}

.sidebar {

}

.searchSideBar {
    padding: 20px 15px;
}

.searchListTitle,
.searchListItem {
    padding-left: 15px;
    padding-right: 15px;
}

@media tablet-landscape-up {
    .blockContent {
        min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
        max-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
    }
}

@media desktop-up {

}
