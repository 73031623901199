.post {
    position: relative;
}

.avatar {
    position: absolute;
    left: 8px;
    bottom: 8px;
    border: 2px solid #fff;
    z-index: 1;
}

.order {
    position: absolute;
    right: 8px;
    bottom: 10px;
}

.orderSum {
    font-size: 1.5rem;
    line-height: 1;
    color: #fff;
    text-shadow: #000 0 0 5px;
}
