@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.itemContent {
    justify-content: center;
}

.searchModal {
    padding: 0 10px;
}

.modalBody {
    padding: 0 0 0 15px;
    max-height: 504px;
}

@media tablet-landscape-up {
    .modalBody {
        min-height: 50vh;
    }
}
