.header {
    position: relative;
    padding: 30px 20px 20px;
}

.header_pRight {
    padding-right: 40px;
}

.title {
    line-height: 1;
}

.title_center {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
}

.title_left {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
}

.close {
    position: absolute;
    top: 16px;
    right: 16px;
    composes: close from "../../../styles/modules/close.module.css";
}
