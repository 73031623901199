.image {

}

.avatar {
    position: absolute;
    border: 2px solid #fff;
}

.avatar_1 {
    top: 0;
    left: 0;
    z-index: 1;
}

.avatar_2 {
    right: 0;
    bottom: 0;
    z-index: 2;
}
