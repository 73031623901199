.wrap {
    display: block;
    min-width: 100%;
}

.image {
    max-width: 100%;
    min-width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity .3s ease;
}

.image_loading {
    opacity: 0;
}
