@value vGray5, vGray16 from "../../../../styles/variables.css";
@value tablet-up from "../../../../styles/breakpoints.css";

.field {
    display: flex;
}

.selectDate,
.selectTime {
    margin-top: 10px;
    flex-basis: 50%;
}

.selectDate {
    margin-right: 10px;
}

.selectTime {

}

.selectField {
    border: 1px solid vGray5;
    border-radius: 8px;
}

.selectField svg path {
    fill: vGray16;
}

.selectOption {
    padding: 4px 10px;
    flex-direction: column;
    cursor: default;
}

.selectOption:hover {
    background-color: inherit;
}

.dateChanger {
    width: 100%;
}

.calendar {
    margin-top: 8px;
}

.calendarWeekday {
    font-size: .75rem;
}

.calendarDate {
    margin-bottom: 4px;
    padding: 6px 6px 8px;
    font-size: .75rem;
}

@media tablet-up {
    .selectDate {
        min-width: 200px;
        margin-top:0;
    }

    .selectTime {
        min-width: 150px;
        margin-top:0;
    }
}
