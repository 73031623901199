.phoneCode {
    display: flex;
    align-items: center;
    padding-right: 4px;
}

.phoneInput {
    padding-left: 0;
    padding-right: 0;
    height: 38px;
    border: none;
}
