.attaches {

}

.attaches_row {
    display: flex;
}

.attaches_column {

}

.modalBody {
    min-height: 60vh;
}

.video {
    width: 100%;
}
