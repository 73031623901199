@value vGutterX from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.sidebar {
    max-width: 100%;
    width: 100%;
    flex: 0 0 auto;
}

@media tablet-up {
    .sidebar {
        padding-left: calc(vGutterX / 2);
        padding-right: calc(vGutterX / 2);
    }
}

@media tablet-landscape-up {
    .sidebar {
        width: 30%;
        padding-bottom: vGutterX;
    }
}

@media desktop-up {
    .sidebar {
        width: 25%;
    }
}

@media big-desktop-up {

}
