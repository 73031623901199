@value tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.payout {
    position: relative;
    border-radius: 12px;
    flex-shrink: 0;
    cursor: pointer;
}

.background {
    border-radius: 12px;
    overflow: hidden;
}

.body {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 20px 24px;
}

.balance {
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.amount,
.currency {
    font-size: 1.5rem;
    line-height: 1;
    color: #fff;
}

.amount {
    font-weight: 500;
}

.desc {
    margin-top: 8px;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

@media tablet-landscape-up {
    .body {
        padding: 30px 20px 30px 24px;
    }

    .amount,
    .currency {
        font-size: 2.25rem;
    }
}

@media desktop-up {
    .payout {
        max-width: 100%;
    }
}

@media big-desktop-up {
    .body {
        padding: 40px 30px 48px 24px;
    }
}
