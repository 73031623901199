.day {

}

.day + .day {
    margin-top: 18px;
}

.date {
    padding: 0 15px 6px;
}

.date:first-letter {
    text-transform: uppercase;
}
