@value vGutter, vPrimary1, vGray14 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.nav {
    display: flex;
    margin-top: calc(vGutter * 2);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.tabs {
    padding: 10px vGutter 0;
    overflow: auto hidden;
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    padding: 10px 12px 0;
}

.action {

}

.action_active {
    cursor: default;
    pointer-events: none;
}

.action + .action {
    margin-left: 16px;
}

.iconLenta1 svg rect,
.iconLenta1 svg path {
    fill: vGray14 !important;
}

.iconLenta1_active svg rect,
.iconLenta1_active svg path {
    fill: #000 !important;
}

.iconLenta2 svg path {
    fill: vGray14 !important;
}

.iconLenta2_active svg path {
    fill: #000 !important;
}

@media tablet-landscape-up {
    .tabs {
        padding: 15px 12px 0;
    }

    .actions {
        padding: 15px 12px 0;
    }
}

@media desktop-up {
    .tabs {
        padding: 15px 24px 0;
    }
}
