.reward {

}

.image {
    margin-top: 10px;
    background-size: contain;
}

.title {
    text-align: center;
}
