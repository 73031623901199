@value vTopNavHeight, vPageVerticalGapTop, vGray10, vGray14 from "../../../styles/variables.css";
@value tablet-landscape-up, desktop-up from "../../../styles/breakpoints.css";

.blockSideBar {
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
    overflow: hidden;
}

.sidebar {
    width: 100%;
}

.searchSideBar {
    padding: 20px 15px;
}

.searchListTitle,
.searchListItem {
    padding-left: 15px;
    padding-right: 15px;
}

@media tablet-landscape-up {
    .sidebar {
        width: 70%;
    }
}

@media desktop-up {
    .sidebar {
        width: 25%;
    }

    .searchNothing {
        padding-top: 190px;
    }
}
