.button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background-color: rgba(0, 0, 0, .3) !important;
    z-index: 1;
}

.icon {
    composes: close close_white from "../../styles/modules/close.module.css";
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.icon:before {
    width: 36px;
}

.icon:after {
    height: 36px;
}
