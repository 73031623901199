.imageTitle {
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, .3);
    text-decoration: none;
}

.text {
    color: #fff;
}

.chevron {
    flex-shrink: 0;
    border-color: #fff;
}
