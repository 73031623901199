@value vGutter from "../../../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.item {
    max-width: calc(50% - vGutter / 2);
    flex-basis: calc(50% - vGutter / 2);
}

.body {
    border-radius: 0;
}

.labels {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
}

.avatarGroupContainer {
    justify-content: center;
}

@media tablet-landscape-up {
    .item {
        max-width: calc(33.33% - vGutter / 2);
        flex-basis: calc(33.33% - vGutter);
    }
}

@media desktop-up {

}

@media big-desktop-up {
    .item {
        max-width: calc(25% - vGutter / 2);
        flex-basis: calc(25% - vGutter);
    }
}
