@value vPrimary3, vWhite1, vBlue1, vViolet1 from "../../styles/variables.css";

.list {

}

.tabs {
    margin-bottom: 20px;
    border-bottom: 2px solid vWhite1;
    overflow-x: auto;
    overflow-y: hidden;
}

.tabsItem {
    padding: 8px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabsItemText {
    font-size: 1rem;
    line-height: 1;
    text-align: center;
}

.tabsItemActive {
    cursor: default;
    color: vPrimary3;
}

.tabsItemActive:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -1px;
    height: 3px;
    background: linear-gradient(90deg, vViolet1, vBlue1);
    border-radius: 4px;
    content: '';
}
