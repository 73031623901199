@value vWhite1, vGray4, vBlue4, vBlue6 from "../../styles/variables.css";

.body {

}

.select {
    background-color: vWhite1;
    border-radius: 8px;
}

.selectField {
    color: vGray4;
}

.selectList {
    max-height: 260px;
}

.attach {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    background-color: vWhite1;
    border-radius: 8px;
}

.attachImage {
    border-radius: 8px;
}

.attachRemove {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    background-color: vBlue6;
    border-radius: 12px;
    color: #fff;
    transition: background-color .3s ease;
}

.attachRemove:hover {
    background-color: vBlue4;
}

.footer {

}
