@value vTopNavHeight, vPageVerticalGapTop, vGray14 from "../../../styles/variables.css";

.block {
    padding: 20px 20px 16px 16px;
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
}

.search {
    margin-bottom: 30px;
}

.actionChat {
    display: block;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
}

.list {

}

.listItem {

}

.listItem + .listItem {
    margin-top: 30px;
}

.noDataText {
    margin-top: 40px;
    font-size: 1.25rem;
    color: vGray14;
}
