@value vHoverTransition, vDanger, vWhite1, vGray4, vGray6 from "../../styles/variables.css";

.row {
    position: relative;
}

.row_12 + .row_12 {
    margin-top: 12px;
}

.row_20 + .row_20 {
    margin-top: 20px;
}

.row_24 + .row_24 {
    margin-top: 24px;
}

.row_32 + .row_32 {
    margin-top: 32px;
}

.row_40 + .row_40 {
    margin-top: 40px;
}

.row_vAlignCenter,
.row_vAlignBottom,
.row_jSpaceBetween {
    display: flex;
    width: 100%;
}

.row_vAlignCenter {
    align-items: center;
}

.row_vAlignBottom {
    align-items: flex-end;
}

.row_jSpaceBetween {
    justify-content: space-between;
}

.field {
    position: relative;
}

.field_attachIcon {
    font-size: 0;
    cursor: pointer;
}

.field_attachBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: vWhite1;
    border-radius: 8px;
    cursor: pointer;
    height: 96px;
    width: 96px;
    transition: background-color vHoverTransition;
}

.field_attachBtn:hover {
    background-color: vGray6;
}

.fieldIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.fieldHead {
    margin-bottom: 12px;
    font-size: 1.125rem;
    line-height: 1;
}

.fieldHead_gray {
    color: vGray4;
}

.error, /* FIXME error -> fieldError */
.fieldError {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 1px 0 0;
    font-size: .75rem;
    line-height: 1;
    color: vDanger;
}

.fieldError_static {
    position: static;
    padding-top: 4px;
}

.invalid {
    border-color: vDanger !important;
}

.dangerText {
    color: vDanger;
}

.group {
    display: flex;
    position: relative;
}

.group_alignCenter {
    align-items: center;
}

.group_justifyCenter {
    justify-content: center;
}

.group_spaceBetween {
    justify-content: space-between;
}

.group_fullWidth {
    flex-basis: 100%;
}

.group_noSidePadding {
    padding-left: 0;
    padding-right: 0;
}

.groupItem {
    position: relative;
}

.groupItem_static {
    position: static;
}

.groupItem_minWidth {
    flex-grow: 0;
    flex-shrink: 0;
}

.groupItem_maxWidth {
    flex-grow: 1;
}

.submitting {
    composes: updating from "common.module.css";
}

.inputFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
}

.controls {

}

.controls_12 {
    margin-top: 12px;
}

.controls_20 {
    margin-top: 20px;
}

.controls_24 {
    margin-top: 20px;
}

.controls_32 {
    margin-top: 20px;
}

.controls_40 {
    margin-top: 20px;
}

.controls_64 {
    margin-top: 64px;
}
