@value vRadiusSmall, vPrimary1, vGray6 from "../../styles/variables.css";

.fieldSelect {
    composes: gray from "../../components/Input/Input.module.css";
}

.fieldText {
    padding: 0 0 0 15px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
}

.subField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.subFieldText {
    padding: 0 0 0 15px;
    font-size: 1.25rem;
    line-height: 1;
}

.reset {
    composes: close from "../../styles/modules/close.module.css";
    position: absolute;
    bottom: 10px;
    right: -30px;
    width: 20px;
    height: 20px;
}

.reset:before,
.reset:after {
    background-color: vGray6;
}

.reset:before {
    width: 20px;
}

.reset:after {
    height: 20px;
}

.switchBox {
    display: flex;
    margin-top: 18px;
    padding: 2px;
    background-color: vPrimary1;
    border-radius: vRadiusSmall;
}

.switch {
    border-radius: vRadiusSmall;
}

.switch_active {
    background-color: #fff;
    color: vPrimary1;
}

.switch_active:not(:disabled):hover {
    cursor: default;
    filter: none;
}

.switch + .switch {
    margin-left: 1px;
}

.range {

}

.age {
    min-width: 90px;
    font-size: 1.25rem;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
}

.controls {
    display: flex;
}

.control {

}

.control + .control {
    margin-left: 24px;
}
