@value vWhite1, vGray2, vBlue5 from "../../../../styles/variables.css";

.progressBar {
    width: 100%;
    margin-bottom: 24px;
}

.wrap {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0;
    border-bottom: 1px solid vWhite1;
}

.progressWrap {
    position: relative;
    height: 14px;
    border-radius: 14px;
    overflow: hidden;
}

.progress {
    position: relative;
    height: inherit;
    background-color: vGray2;
    border-radius: 0 14px 14px 0;
    transition: margin-left .6s ease-in-out;
}

.progressDefaultValue {
    position: absolute;
    width: 14px;
    height: inherit;
    border-radius: 50%;
    content: '';
}

.valueWrap {
    margin-bottom: 8px;
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.value {
    font-size: .875rem;
    line-height: 1;
    white-space: nowrap;
}

.desc {
    display: flex;
    justify-content: space-between;
    margin: 12px 4px 0;
    font-size: 1.25rem;
    line-height: 1;
}
