@value vGray12, vGray14 from "../../../../styles/variables.css";

.item {
    padding: 10px 15px;
}

.user {
    display: block;
}

.friends {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 30px;
    text-decoration: none;
}

.friendsText {
    font-size: .875rem;
    color: vGray14;
    white-space: nowrap;
}

.friendsText_mLeft {
    margin-left: 8px;
}
