@value vPrimary3 from "../../styles/variables.css";

.label {

}

.label:active .switch:after {
    width: 20px;
}

.input {
    position: absolute;
    margin: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.input:checked + .switch {
    background: vPrimary3;
}

.input:checked + .switch:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}

.switch {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 10px;
    position: relative;
}

.switch:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 8px;
    transition: 0.3s;
}
