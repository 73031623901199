@value vPrimary3 from "../../../../styles/variables.css";

.link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
}

.link:not(.link_active):not(.link_disabled) {
    cursor: pointer;
}

.link_active {
    cursor: default;
}

.link_disabled {
    pointer-events: none;
}

.linkChild {
    padding-top: 15px;
}
