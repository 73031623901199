.content {
    word-break: break-word;
    white-space: pre-wrap;
}

.smileWrap {
    height: 210px;
    width: 210px;
}

.smile {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    width: 24px;
}
