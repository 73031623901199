@value vHoverTransition, vGray1, vGray4, vGray6, vGray9 from "../../styles/variables.css";

.slider {
    position: relative;
}

.items {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.items[data-keen-slider-v] {
    flex-wrap: wrap;
}

.items[data-keen-slider-v] .item {
    width: 100%;
}

.items[data-keen-slider-moves] * {
    pointer-events: none;
}

.item {
    overflow: hidden;
}

.arrow,
.previewsArrow {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
}

.arrow {
    width: 10%;
}

.previewsArrow {
    width: 30px;
}

.arrow:hover .arrowIcon,
.previewsArrow:hover .arrowIcon {
    border-color: vGray1;
}

.arrow_left {
    left: 0;
    padding-left: 20px;
    justify-content: flex-start;
}

.arrow_right {
    right: 0;
    padding-right: 20px;
    justify-content: flex-end;
}

.arrowIcon {
    width: 18px;
    height: 18px;
    transition: border-color vHoverTransition;
}

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
}

.dot {
    width: 12px;
    height: 12px;
    background-color: vGray6;
    border-radius: 50%;
    cursor: pointer;
}

.dot + .dot {
    margin-left: 10px;
}

.dot_active {
    background-color: vGray4;
    cursor: default;
}

.dot_viewedFirst,
.dot_viewedLast {
    width: 8px;
    height: 8px;
}

.previews {
    position: relative;
    margin-top: 10px;
    overflow: hidden;
}

.previewsItems {
    display: flex;
    transition: transform .6s ease-in-out;
}

.previewsItems_left {
    justify-content: flex-start;
}

.previewsItems_center {
    justify-content: center;
}

.preview {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border: 1px solid vGray9;
    cursor: pointer;
    overflow: hidden;
    transition: border-color .3s ease;
}

.preview + .preview {
    margin-left: 10px;
}

.preview_active {
    border-color: #000;
    cursor: default;
}

.previewImage {
    max-width: 100%;
    height: auto;
}
