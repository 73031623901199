@value tablet-landscape-up from "../../styles/breakpoints.css";

.wrap {
    display: flex;
    align-items: center;
}

.image {
    border-style: solid;
    border-color: #fff;
    border-radius: 50%;
    z-index: 1;
}
