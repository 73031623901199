@value vGutter from "../../../../styles/variables.css";
@value phone-only from "../../../../styles/breakpoints.css";

.title {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 1.25rem;
    font-weight: 400;
}

.controls {
    margin-top: vGutter;
}

@media phone-only {
    .title {
        margin-left: vGutter;
        margin-right: vGutter;
    }

    .controls {
        margin-left: vGutter;
        margin-right: vGutter;
    }
}
