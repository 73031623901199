@value vBoxShadow, vViolet3 from "../../styles/variables.css";

.wrap {
    position: relative;
}

.options {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    box-shadow: vBoxShadow;
    border-radius: 6px;
    z-index: 10;
}

.options:not(.options_active) {
    display: none;
}

.list {
    max-height: 300px;
    margin: 0;
    padding: 8px 0;
    list-style: none;
    overflow-y: auto;
}

.noData {
    padding: 10px;
    text-align: center;
}
