@value vPrimary1, vPrimary3, vGray1, vGray10 from "../../styles/variables.css";

.footer {
    padding: 0 12px;
    border-top: 2px solid vGray10;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 15px 0;
    marker-start: none;
}

.item {
    padding-right: 4px;
    list-style: none;
}

.item + .item:before {
    padding-right: 4px;
    content: '\2022';
}

.navLink {
    line-height: 1.3;
    color: vGray1;
    text-decoration: none;
    outline: none;
    transition: color .3s ease;
}

.navLink:hover {
    color: vPrimary3;
}

.copyright {
    text-align: center;
}

.copyrightIcon {
    margin: 10px auto 0;
}

.copyrightIcon svg path {
    fill: vPrimary1;
}
