@value vGray3 from "../../../../styles/variables.css";
@value desktop-up from "../../../../styles/breakpoints.css";

.header {
    display: none;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1;
}

@media desktop-up {
    .header {
        display: table-header-group;
    }
}
