@value vGutter, vGray2 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.desc {
    padding: 8px 12px;
    background-color: vGray2;
    font-size: 1rem;
    word-wrap: break-word;
}

.text {
    padding: 12px vGutter;
    font-size: 1.25rem;
    word-break: break-word;
}

@media tablet-landscape-up {
    .text {
        padding-left: 24px;
        padding-right: 24px;
    }
}
