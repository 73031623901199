@value vRadius, vPrimary1, vWhite1, vGray14 from "../../styles/variables.css";

.slider {
    position: relative;
    width: 100%;
    height: 12px;
}

.track,
.range {
    position: absolute;
    border-radius: vRadius;
    height: 12px;
}

.track {
    width: 100%;
    background-color: vWhite1;
    z-index: 1;
}

.range {
    background-color: vPrimary1;
    z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    position: absolute;
    width: 100%;
    height: 0;
    pointer-events: none;
    outline: none;
}

.thumb_zindex3 {
    z-index: 3;
}

.thumb_zindex4 {
    z-index: 4;
}

.thumb_zindex5 {
    z-index: 5;
}

.thumb::-webkit-slider-thumb {
    position: relative;
    width: 28px;
    height: 16px;
    background-color: vGray14;
    background-image: url(../../assets/svg/slider-thumb.svg);
    background-position: -1px -1px;
    background-repeat: no-repeat;
    border: none;
    border-radius: vRadius;
    cursor: pointer;
    pointer-events: all;
    transform: translateY(4px);
}

.thumb::-moz-range-thumb {
    position: relative;
    width: 28px;
    height: 16px;
    background-color: vGray14;
    background-image: url(../../assets/svg/slider-thumb.svg);
    background-position: -1px -1px;
    background-repeat: no-repeat;
    border: none;
    border-radius: vRadius;
    cursor: pointer;
    pointer-events: all;
    transform: translateY(4px);
}
