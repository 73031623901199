@value vGutter, vWhite1 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.content {

}

.content_center {
    margin-left: auto;
    margin-right: auto;
}

.header {

}

.headerAction {
    width: auto;
    margin-left: vGutter;
}

.items {
    margin: 10px vGutter 0;
}

.updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.modalFormHeader {
    /*border-top: 3px solid vWhite1;*/
}

.modalFormBody {
    padding: 12px 20px 0;
}

.modalFormFooter {
    padding: 20px;
}

@media tablet-up {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .headerAction {
        margin-left: 0;
    }

    .items {
        margin-left: 0;
        margin-right: 0;
    }
}
