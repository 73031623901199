.company-agreement {

}

.logo {
    width: 128px;
    height: 128px;
    margin: 0 auto;
}

.logoImg {
    display: block;
    max-width: 100%;
    height: auto;
}

.item {
    padding: 20px 0;
}

.text {
    padding: 0 0 10px;
    font-size: 14px;
}

.link {

}

.labelCheck {
    display: block;
    padding: 10px 0 0;
    font-size: 14px;
}
