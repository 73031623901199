@value vGreen1, vGray1, vGutterX from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up from "../../styles/breakpoints.css";

.block {
    padding: 12px;
}

.subTitle {
    padding: 0 0 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.row {
    margin-top: 32px;
}

.field {
    position: relative;
}

.field + .field {
    margin-top: 12px;
}

.fieldLabel {
    font-weight: 500;
}

.fieldDesc {
    margin-left: 41px;
    color: vGray1;
}

.fieldSelect {
    font-size: 1.125rem;
    color: #000;
}

.fieldSelectOption {
    min-height: 44px;
    padding-top: 0;
    padding-bottom: 0;
}

.fieldSelectOptionImg {
    width: 32px;
    margin-right: 8px;
    overflow: hidden;
}

.receiveControls {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 20px;
}

.receiveControl {
    margin: 0 12px 12px 0;
}

.receiveControl:last-child {
    margin: 0;
}

.iframePayment {
    width: 100%;
    height: calc(100% - 60px);
    min-height: 480px;
    background-color: #fff;
    border: none;
    flex-grow: 1;
}

@media tablet-up {
    .receiveControl {
        margin-right: 20%;
        margin-bottom: 0;
    }
}

@media tablet-landscape-up {
    .order {
        padding: 24px;
    }
}
