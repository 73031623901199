.form {
    position: relative;
}

.input {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 90px;
    height: auto;
}

.actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
}
