@value vLinkColor, vGray11 from "../../styles/variables.css";

.text {
    padding: 0 16px;
    font-size: 14px;
    color: vGray11;
    text-align: center;
}

.timer,
.action {
    padding: 20px 0 0;
    text-align: center;
}

.actionLink {
    font-size: 16px;
    color: vLinkColor;
}
