@font-face {
    font-family: "Roboto";
    src: local("Roboto-Light"), url("../assets/fonts/roboto/Roboto-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto-Regular"), url("../assets/fonts/roboto/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto-Medium"), url("../assets/fonts/roboto/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto-Bold"), url("../assets/fonts/roboto/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
