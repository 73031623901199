@value desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.feed {
    position: relative;
    flex-grow: 1;
}

.tabsItem {

}

@media desktop-up {
    .tabsItem {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media big-desktop-up {
    .tabsItem {
        padding-left: 30px;
        padding-right: 30px;
    }
}
