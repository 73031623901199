@value vGutter, vRadius, vGray4, vGray7 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up from "../../styles/breakpoints.css";

.block {
    position: relative;
    padding-bottom: 0;
    border-radius: 0;
}

.body {
    display: flex;
    padding: 0 0 24px;
}

.footer {
    padding: 0;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 18px;
}

.infoTitle {
    padding: 0 80px 8px 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    color: #000;
    text-decoration: none;
    transition: color .3s ease;
}

.infoTitle:hover {
    color: vGray7;
}

.infoDesc {
    padding: 8px 0 0;
    font-size: 1.125rem;
    color: vGray4;
    line-height: 1;
}

.labels {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
}

@media tablet-up {
    .block {
        border-radius: vRadius;
    }
}

@media tablet-landscape-up {
    .tabs {
        justify-content: space-between;
    }

    .tabsItem {
        flex-basis: 100%;
        text-align: center;
    }
}
