@value vGray14 from "../../../../styles/variables.css";

.header {
    display: flex;
    flex-basis: 100%;
    padding: 12px 0 0;
}

.content {
    padding: 0 10px 0 20px;
    flex-grow: 1;
}

.avatar {
    display: block;
}

.name {
    font-weight: 600;
    color: #000;
    text-decoration: none;
}

.date {
    padding: 2px 0 0;
    color: vGray14;
    text-decoration: none;
}

.menu {

}
