@value vPrimary1 from "../../../../styles/variables.css";

.image {
    position: relative;
    cursor: pointer;
}

.body {
    cursor: pointer;
}

.title {
    color: vPrimary1;
}

.add {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -25%);
    z-index: 1;
}

.iconAdd {
    composes: cross from "../../../../styles/modules/common.module.css";
    width: 60px;
    height: 60px;
}

.iconAdd:before,
.iconAdd:after {
    background-color: vPrimary1;
}

.iconAdd:before {
    width: 60px;
    height: 3px;
}

.iconAdd:after {
    width: 3px;
    height: 60px;
}
