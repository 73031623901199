.sort {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    padding: 0 5px;
}

.arrow {
    width: 5px;
    height: 6px;
    background: url(../../../../assets/svg/arrow_sort.svg) no-repeat;
}

.arrow + .arrow {
    margin-top: 1px;
}

.arrow_up {
    transform: rotate(180deg);
}

.arrow_down {

}

.arrow_active {
    background: url(../../../../assets/svg/arrow_sort_active.svg) no-repeat;
}
