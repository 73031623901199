@value vWhite1 from "../../../../styles/variables.css";

.search {
    display: flex;
}

.search_compact .input {
    padding-right: 40px;
}

.search_compact .iconSearch {
    right: 10px;
}

.search_compact .actions {
    padding-left: 10px;
}

.field {
    position: relative;
    display: block;
    flex-grow: 1;
}

.input {
    padding-right: 50px;
    background-color: vWhite1;
    border-color: vWhite1;
    font-size: 1.125rem;
}

.actions {
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.iconSearch {
    position: absolute;
    top: 7px;
    right: 20px;
}

.iconSearch svg path {
    stroke: #000;
}

.iconSearch_disabled {
    composes: disabled from "../../../../styles/modules/common.module.css";
}
