@value desktop-up from "../../../../styles/breakpoints.css";

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

@media desktop-up {
    .item {
        margin: 4px 6px;
        width: 44px;
        height: 44px;
    }
}
