.wrap {
    /*height: 100%;*/
}

.link {
    display: block;
    height: 100%;
}

.image {
    background-size: cover;
}
