.filter {
    position: relative;
}

.fields {
    display: flex;
    margin-top: 20px;
}

.field {
    position: relative;
    flex-basis: 50%;
}

.field + .field {
    margin-left: 20px;
}
