@value vColumnGutter: 8px;

.columns {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(vColumnGutter / -2);
    margin-right: calc(vColumnGutter / -2);
}

.column {
    margin: 0 calc(vColumnGutter / 2) vColumnGutter;
    width: 100%;
}

.column_1_2 {
    width: calc(50% - vColumnGutter);
}

.column_1_3 {
    width: calc(50% - vColumnGutter);
}
