@value vGutter, vBlue11, vGray3, vGray14, vRed4, vYellow4, vBlue8, vGreen2 from "../../../../styles/variables.css";
@value tablet-up, desktop-up from "../../../../styles/breakpoints.css";

.list {

}

.header {

}

.headerTitle {
    font-size: .75rem;
}

.headerSort {

}

.row {

}

.col {
    padding: 10px;
    width: 50%;
    font-size: .875rem;
    line-height: 1.15;
}

.col_header {
    vertical-align: middle;
}

.col_body {

}

.colTitle {
    padding: 0 0 5px;
    font-weight: 500;
}

.colInner {
    display: flex;
    font-size: .875rem;
    line-height: 1;
}

.colText {

}

.colText + .colText {
    margin-top: 6px;
}

.colText_row {
    display: block;
}

.colText_noWrap {
    white-space: nowrap;
}

.colText_marker:before {
    position: relative;
    padding: 0 8px 0 0;
    content: '•';
}

.link {
    color: #000;
}

.link_blue {
    color: vBlue11;
    text-decoration: none;
}

.status_default {
    color: vGray14;
}

.status_20 {
    color: vRed4;
}

.status_30 {
    color: vYellow4;
}

.status_40 {
    color: vBlue8;
}

.status_50 {
    color: vGreen2;
}

.status_60 {
    color: vGray14;
}

.noDataText {
    margin-top: 0;
}

@media tablet-up {
    .col {
        width: 33.33%;
    }
}

@media desktop-up {
    .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .row > .col_body {
        border-top: 1px solid vGray3;
    }

    .col {
        padding: 15px;
        width: 20%;
    }

    .colTitle {
        display: none;
    }
}
