@value vPrimary1, vGray14 from "../../../../styles/variables.css";

.content {

}

.title {
    margin-top: 4px;
    font-size: .875rem;
}

.header {
    position: relative;
    margin-top: 10px;
    padding-left: 12px;
}

.header:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    content: '';
    background-color: vGray14;
}

.header_sender:before {
    background-color: vPrimary1;
}

.header_clickable {
    cursor: pointer;
}

.header + .contentMessage,
.header + .contentImage,
.header + .contentVideo {
    margin-top: 10px;
}

.name {
    padding: 0 0 4px;
    font-size: .875rem;
}

.name_sender {
    color: vPrimary1;
}

.headerMessage {
    font-size: .875rem;
}

.headerImage,
.headerVideo,
.headerSticker {
    max-width: 75px;
}

.contentMessage {
    display: block;
}

.contentImage,
.contentVideo {
    cursor: pointer;
}

.contentImage > img {
    max-height: 420px;
    block-size: revert-layer;
    object-fit: contain;
    width: auto;
}

.contentSticker {
    max-height: 210px;
    max-width: 210px;
    block-size: revert-layer;
    object-fit: contain;
    width: auto;
}
