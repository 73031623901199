@value vGreen1 from "../../styles/variables.css";

.badge {
    display: inline-block;
    border-radius: 15px;
    text-align: center;
    background-color: vGreen1;
    color: #fff;
}

.badge_changed {
    animation: sizeChange 1s ease;
    animation-iteration-count: 1;
}

.badge_small {
    padding: 0 6px;
    min-width: 22px;
    height: 22px;
    font-size: .875rem;
    line-height: 24px;
}

.badge_medium {
    padding: 0 8px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
}

.badge_large {

}

@keyframes sizeChange {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.125);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.125);
    }
    100% {
        transform: scale(1);
    }
}
