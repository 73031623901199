@value vGutter from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.item {
    max-width: calc(50% - vGutter / 2);
    flex-basis: calc(50% - vGutter / 2);
    margin-bottom: vGutter;
}

@media tablet-landscape-up {
    .item {
        max-width: calc(33.33% - vGutter / 2);
        flex-basis: calc(33.33% - vGutter);
    }
}
