.image {
    max-width: 30px;
    max-height: 20px;
}

.item {
    min-height: 44px;
    padding-top: 0;
    padding-bottom: 0;
}

.itemImg {
    width: 32px;
    margin-right: 8px;
    overflow: hidden;
}

.selectField {
    font-size: 1rem;
}
