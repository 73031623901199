@value vWhite1 from "../../styles/variables.css";

.app {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: vWhite1;
}

.loader {
    height: 100vh;
    background-color: #fff;
}
