@value vTopNavHeight from "../../styles/variables.css";

.logoImageWrap {
    height: 100%;
}

.logoImage {
    display: inline-block;
    vertical-align: top;
    width: auto;
    height: 100%;
    max-height: calc(vTopNavHeight - 16px);
}

.logoImage svg * {
    fill: #fff;
}
