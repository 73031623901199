@value vGutterX from "../../styles/variables.css";
@value tablet-landscape-up, desktop-up from "../../styles/breakpoints.css";

.content {
    padding-right: 0;
}

.block {
    padding: 20px 10px;
}

@media tablet-landscape-up {
    .block {
        padding-left: vGutterX;
        padding-right: vGutterX;
    }
}

@media desktop-up {
    .content {
        padding-right: vGutterX;
    }
}
