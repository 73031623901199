@value vWhite1 from "../../../../../../styles/variables.css";

.item {
    align-items: center;
    background-color: vWhite1;
}

.link {
    cursor: pointer;
}
