@value vGutter, vPrimary1, vWhite1, vGray1, vGray2, vGray8 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.postForm {
    position: relative;
}

.form {
    padding: 16px vGutter;
}

.header {
    display: flex;
    align-items: center;
    padding: 0 0 12px;
    border-bottom: 2px solid vWhite1;
}

.body {
    display: flex;
    align-items: center;
    padding: 12px 0 0;
}

.buttonAddPost {
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: vGray2;
    border-radius: 8px;
    color: vGray8;
}

.overlay {
    composes: overlay from "../../styles/modules/overlay.module.css";
    composes: overlay_light from "../../styles/modules/overlay.module.css";
    z-index: 10;
}

.overlay_show {
    composes: show from "../../styles/modules/overlay.module.css";
}

.selectCommunity {
    display: flex;
    align-items: center;
    min-height: 36px;
}

.selectCommunity_active {

}

.desc {
    margin-right: 12px;
    font-size: 1.25rem;
}

.error {

}

.select {
    display: flex;
    align-items: center;
}

.select_active {
    cursor: pointer;
}

.selectText {
    font-size: 1.5rem;
    color: vGray1;
}

.selectText_active {
    color: vPrimary1;
}

.dropDown {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    background-color: vWhite1;
    border-radius: 30px;
}

.iconDropDown {
    composes: arrow from "../../styles/modules/arrow.module.css";
    composes: arrow_medium from "../../styles/modules/arrow.module.css";
    composes: arrow_down from "../../styles/modules/arrow.module.css";
    border-color: vGray1;
}

.iconDropDown_active {
    border-color: vPrimary1;
}

@media tablet-landscape-up {
    .form {
        padding: 22px 24px;
    }
}
