.item {

}

.item_hidden {
    display: none;
}

.itemText {
    padding-left: 10px;
}

.iconAdd {

}

.iconChat svg path {
    stroke: #000;
}

.iconChannel svg path {
    stroke: #000;
}
