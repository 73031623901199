@value vGutter, vGutterX from "../../styles/variables.css";
@value desktop-up, tablet-landscape-up, tablet-up from "../../styles/breakpoints.css";

.header {
    display: flex;
    align-items: center;
    padding-left: vGutter;
    padding-right: vGutter;
}

.buttonBack {
    display: block;
    width: auto;
    padding-left: 3px;
    padding-bottom: calc(vGutterX / 2);
}

.title {

}

.title_left,
.title_center,
.title_right {
    flex-grow: 1;
}

.title_left {
    text-align: left;
}

.title_center {
    text-align: center;
}

.title_right {
    text-align: right;
}

.content {
    padding-bottom: calc(vGutterX / 2);
}

@media tablet-up {
    .header {
        padding-left: 0;
        padding-right: 0;
    }
}

@media tablet-landscape-up {
    .content {
        padding-bottom: 0;
    }

    .buttonBack {
        padding-bottom: 0;
    }
}
