@value vHoverTransition, vGray4 from "../../../../styles/variables.css";

.trigger {
    display: flex;
    padding: 5px;
    cursor: pointer;
}

.trigger:hover .dot {
    background-color: #000;
}

.trigger_horizontal {
    flex-direction: row;
}

.trigger_vertical {
    flex-direction: column;
}

.trigger_horizontal .dot + .dot {
    margin-left: 4px;
}

.trigger_vertical .dot + .dot {
    margin-top: 4px;
}

.dot {
    width: 4px;
    height: 4px;
    background-color: vGray4;
    border-radius: 50%;
    transition: background-color vHoverTransition;
}
