.title {
    text-align: center;
}

.body {

}

.body:before {
    display: block;
    font-size: 1.5rem;
    line-height: 2;
    color: #000;
    content: '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *';
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.goodsItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 12px;
}

.price {
    font-size: 1.125rem;
    line-height: 1;
    text-align: right;
    white-space: nowrap;
}

.customPrice {
    font-size: .8125rem;
    line-height: 1;
    text-align: right;
    white-space: nowrap;
}

.discount {

}

.discount:before,
.discount:after {
    display: block;
    font-size: 1.5rem;
    line-height: 2;
    color: #000;
    content: '-----------------------------------------------------------------------------------------------';
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.discountInner {
    display: flex;
    justify-content: space-between;
}

.discountText {

}

.discountPrice {

}

.total {

}

.qrCode {
    display: block;
    margin: 20px auto 0;
}

.order {
    padding: 20px 0 0;
}

.orderText {
    line-height: 1.2;
    text-align: center;
}

