@value vGray10 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.wrap {
    position: relative;
    margin: 52px 12px 10px 4px;
}

.list {

}

.list_loading {
    composes: updating from "../../../../styles/modules/common.module.css";
}

@media tablet-landscape-up {
    .wrap {
        margin: 62px 0 10px 24px;
    }
}
