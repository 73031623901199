@value tablet-landscape-up from "../../styles/breakpoints.css";

.header {
    min-height: 39px; /* 1 line + padding PageTitle .title */
}

@media tablet-landscape-up {
    .header {
        min-height: 64px;
    }
}
