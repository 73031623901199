@value vWhite1 from "../../styles/variables.css";

.container {
    position: relative;
    background-color: vWhite1;
}

.container_tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
}

.post {
    position: relative;
    margin-top: 2px;
}
