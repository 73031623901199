@value vHoverTransition, vGray12, vGray14 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.item {
    display: flex;
    flex-basis: 100%;
    padding: 12px 15px;
    background-color: #fff;
    text-decoration: none;
    transition: background-color vHoverTransition;
}

.item_active {
    cursor: default;
}

.item_hover:hover,
.item_active {
    background-color: vGray12;
}

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 4px 0 10px;
}

.info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

@media tablet-landscape-up {
    .content {
        padding: 0 4px 0 16px;
    }
}
