@value vViolet0 from "../../../../styles/variables.css";

.chart {

}

.wrap {
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}

.tabsWrap {
    display: flex;
    justify-content: center;
}

.tabs {
    display: inline-flex;
    margin: 20px 0 10px;
    border: 1px solid vViolet0;
    border-radius: 10px;
    overflow: hidden;
}

.tabsItem {
    padding: 8px 24px;
    font-size: 1rem;
    color: vViolet0;
    transition: color .1s ease, background-color .1s ease;
}

.tabsItem + .tabsItem {
    border-left: 1px solid vViolet0;
}

.tabsItem:hover,
.tabsItemActive {
    background-color: vViolet0;
    color: #fff;
}
