@value vGray14, vBlue7 from "../../../../styles/variables.css";

.content {
    padding: 0 0 0 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info {
    flex-grow: 1;
}

.data {

}

.arrow {
    padding-left: 24px;
}

.title {
    font-weight: 500;
    color: #000;
    line-height: 1;
}

.subtitle {
    margin-top: 5px;
    color: vGray14;
    line-height: 1;
}

.amount {
    font-size: 1.25rem;
    line-height: 1;
    text-align: right;
    white-space: nowrap;
}

.amount_positive {
    color: vBlue7;
}

.amount_negative {

}

.approve {
    margin-top: 4px;
    font-size: .875rem;
    line-height: 1;
    color: vGray14;
    text-align: right;
}

.chevron {
    display: block;
}
