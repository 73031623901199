@value vGutter from "../../../../styles/variables.css";

.fullWidth {
    width: 100%;
}

.halfWidth {
    margin-bottom: 8px;
    max-width: calc(50% - vGutter / 2);
    flex-basis: calc(50% - vGutter / 2);
}

.thirdWidth {
    margin-bottom: 8px;
    max-width: calc(33.33% - vGutter / 2);
    flex-basis: calc(33.33% - vGutter);
}
