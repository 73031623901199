@value vBoxShadow, vGray5 from "../../../styles/variables.css";

.options {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 0;
    margin-top: 4px;
    background-color: #fff;
    box-shadow: vBoxShadow;
    border-radius: 6px;
    visibility: hidden;
    z-index: 10;
}

.options_visible {
    visibility: visible;
    height: auto;
}

.list {
    composes: scrollbar from "../../../styles/modules/common.module.css";
    max-height: 300px;
    margin: 8px 0 0;
    padding: 0 0 8px;
    list-style: none;
    overflow-y: auto;
}

.noResult {
    padding: 20px 0 20px 8px;
    font-size: .875rem;
    line-height: 1;
    cursor: default;
    text-align: center;
}
