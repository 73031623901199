.datepicker {

}

.changer {
    padding: 0 2px 16px;
}

.titleTime {
    padding: 12px 0 8px;
    text-align: center;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1;
}

.fieldTime {
    composes: default from "../Input/Input.module.css";
    font-size: 1rem;
}

.optionsTime {
    max-height: 100px;
}

.optionTime {
    font-size: .75rem;
}
