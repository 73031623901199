@value vGutter, vGray0 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up from "../../styles/breakpoints.css";

.pageHeaderContent {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
}

.ticket {
    margin-top: 10px;
}

.ticketItems {
    padding: 20px vGutter 0;
}

.formFieldWrap {
    padding: 20px vGutter;
}

.updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.avatarWrap {
    display: flex;
    align-items: center;
    padding: 10px 0 0;
}

.avatarUser {
    margin-left: auto;
}

.avatarName {
    padding: 0 0 0 10px;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1;
}

@media tablet-up {
    .ticketItems {
        padding: 20px 20px 0;
    }

    .formFieldWrap {
        padding: 20px 20px 40px;
    }
}

@media tablet-landscape-up {
        .header {
        align-items: center;
    }
}
