.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity .3s ease-in-out;
    opacity: 0;
    z-index: 100;
}

.overlay_dark {
    background-color: rgba(0, 0, 0, 0.9);
}

.overlay_medium {
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay_light {
    background-color: rgba(0, 0, 0, 0.3);
}

.overlay_transparent {
    background-color: rgba(0, 0, 0, 0);
}

.show {
    opacity: 1;
}

.hide {
    opacity: 0;
    height: 0;
}
