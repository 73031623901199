@value vGutterX from "../../styles/variables.css";

.catalog {
    position: relative;
    margin-top: calc(vGutterX / 2);
}

.modalIframeOverlay {
    z-index: 20;
}

.modalIframe {
    height: 100%;
}

.modalIframeBody {
    max-height: none;
    height: 100%;
    padding: 0;
    overflow-y: hidden;
}
