@value vGray2 from "../../../../styles/variables.css";

.desc {
    position: relative;
    margin-top: 15px;
}

.textarea {
    resize: vertical;
}
