@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.item {
    padding: 0 12px;
}

@media tablet-landscape-up {
    .item {
        padding: 0 16px;
    }

    .item + .item {
        margin-top: 24px;
    }
}
